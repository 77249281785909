import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";

export default function SearchBox({ className, onChange, value, placeholder }) {
  return (
    <div className={`relative ${className}`}>
      <div className="flex items-center w-full appearance-none px-3 flex-1">
        <MagnifyingGlassIcon className="w-5" />
        <input
          placeholder={placeholder}
          className="bg-transparent flex-1 w-full outline-none ml-3 py-1 appearance-none"
          onChange={onChange}
          value={value}
        />
        {/* <input
          placeholder={placeholder}
          className="bg-transparent flex-1 w-full outline-none ml-3 py-2 appearance-none"
          onChange={(e) => setSearchText(e.target.value)}
          value={searchText}
        /> */}
      </div>
    </div>
  );
}
