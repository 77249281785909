import React, { useRef, useState } from "react";
import { BsFilter } from "react-icons/bs";
import FilterPopup from "./FilterPopup";
const CustomFilters = ({
  handleNewFilter,
  handleFilterChange,
  filterModel,
  columns,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  function handleFilter(e) {
    setIsOpen(!isOpen);
  }
  function handleCLoseFilter() {
    setIsOpen(false);
  }
  const filterRef = useRef();
  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      handleCLoseFilter();
    }
  };
  const handleClickOutside = (event) => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      handleCLoseFilter();
    }
  };
  document.addEventListener("keydown", handleHideDropdown, true);
  document.addEventListener("click", handleClickOutside, true);

  return (
    <div className="inline-block relative" ref={filterRef}>
      {filterModel?.rules?.length ? (
        <span className="absolute bg-[#1976d2] text-white rounded-full w-5 h-5 -right-2 -top-2 flex justify-center items-center">
          <small className="font-semibold">{filterModel.rules.length}</small>
        </span>
      ) : null}
      <button onClick={handleFilter} className="btnTable">
        <BsFilter className="mr-2 h-6 w-6 text-[#1976d2] font-medium" />
        <span className="text-[#1976d2] font-medium">FILTERS</span>
      </button>
      {isOpen && (
        <FilterPopup
          onCloseFilter={handleCLoseFilter}
          columns={columns}
          handleNewFilter={handleNewFilter}
          filterChange={handleFilterChange}
          filterModel={filterModel}
        />
      )}
    </div>
  );
};

export default CustomFilters;
