import React from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import MUIToolbar from "./MUIToolbar";
const MUIDataTable = React.memo((props) => {
  const {
    totalItems,
    onPageSizeChange,
    onPaginationModelChange,
    paginationModel,
    items,
    columnDefs,
    getRowId,
    paginationMode,
    gridOptions,
    autoHeight,
    checkboxSelection,
    onRowSelectionModelChange,
    ref,
    additionalMsg,
    customSize,
    additionalStatus,
    additionalStatusValue,
    pagination,
    searchable,
    height,
    exportButton,
    handleExport,
    searchCounter,
    onFilterModelChange,
    CustomToolbar,
    processRowUpdate,
    onCellEditStart,
    onProcessRowUpdateError,
    onCellEditStop,
    cellModesModel,
    displayCount,
    rowModesModel,
    onRowModesModelChange,
    onRowEditStart,
    onRowEditStop,
    onRowEditCommit,
    pinnedColumns,
    CustomComponent,
    showCount,
    LineOne,
    rowReordering,
    handleRowOrderChange,
    getRowClass,
    filterMode,
    treeData,
    getTreeDataPath,
    groupingColDef,
    defaultGroupingExpansionDepth,
    expendedRows,
    onRowClick,
    disableRowSelectionOnClick,
    sx,
    toolbar,
    onColumnOrderChange,
    hideFooter,
  } = props;
  const [showExportOptions, setShowExportOptions] = React.useState(false);
  const optionsRef = React.useRef();
  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      setShowExportOptions(false);
    }
  };
  const handleClickOutside = (event) => {
    if (optionsRef.current && !optionsRef.current.contains(event.target)) {
      setShowExportOptions(false);
    }
  };
  document.addEventListener("keydown", handleHideDropdown, true);
  document.addEventListener("click", handleClickOutside, true);
  return (
    <div>
      <div className="ml-auto mr-auto relative" style={{ overflowX: "hidden",overflowY: "hidden" }}>
        <div className="flex items-center justify-end absolute w-full">
          {displayCount !== "No" ? (
            <>
              <div className="grid grid-cols-12">
                <div className={["col-span-12"].join(" ")}>
                  {showCount !== "No" ? (
                    <div className="w-full py-[5px] text-base font-normal font-mont text-heading my-2 flex flex-wrap justify-between mr-4">
                      {items?.length > 0
                        ? searchCounter === "Yes"
                          ? `Displaying 1-${items?.length} of ${
                              totalItems ? totalItems : items?.length
                            } result ${additionalMsg ? additionalMsg : ""}`
                          : pagination === "No"
                          ? `Displaying 1-${items?.length} of ${
                              totalItems ? totalItems : items?.length
                            } result ${additionalMsg ? additionalMsg : ""}`
                          : `Displaying ${
                              (paginationModel?.page - 1) *
                                paginationModel?.pageSize +
                              1
                            }-${Math.min(
                              paginationModel?.page * paginationModel?.pageSize,
                              totalItems ? totalItems : items?.length
                            )} of ${
                              totalItems ? totalItems : items?.length
                            } result ${additionalMsg ? additionalMsg : ""}`
                        : `Displaying 0-0 of ${
                            totalItems
                              ? totalItems
                              : items?.length
                              ? items?.length
                              : 0
                          } result ${additionalMsg ? additionalMsg : ""}`}
                      {searchable === "No" ? (
                        props?.exportOption?.length > 0 ? (
                          <div className="relative">
                            <button
                              onClick={() =>
                                setShowExportOptions(!showExportOptions)
                              }
                              text="Export"
                              className="relative float-right border ml-1 min-w-[119px] w-full h-[31px] text-sm"
                            >
                              Export
                              {showExportOptions && (
                                <div className="absolute w-[101.5%] !mt-1.25 bg-white z-10 border-x border-b border-[#ddd] -ml-px overflow-hidden transition ease-in-out delay-150">
                                  <ul ref={optionsRef} className="!pl-0 !mb-0">
                                    {props.exportOption?.map(
                                      (option, index) => {
                                        return (
                                          <li
                                            key={index}
                                            className="cursor-pointer px-2.5 !py-1.25 border-t border-[#ddd] hover:bg-[#e1e1e1]"
                                            onClick={() =>
                                              handleExport(option.value)
                                            }
                                          >
                                            {option.label}
                                          </li>
                                        );
                                      }
                                    )}
                                  </ul>
                                </div>
                              )}
                            </button>
                          </div>
                        ) : exportButton ? (
                          <div>
                            <button
                              onClick={exportButton}
                              text="Export"
                              className="relative float-right border ml-1 w-[119px] h-[31px] text-sm"
                            >
                              Export
                            </button>
                          </div>
                        ) : null
                      ) : null}
                      {LineOne ? <LineOne /> : null}
                    </div>
                  ) : null}
                </div>
              </div>
            </>
          ) : null}
          {searchable !== "No" ? (
            <div className="flex flex-wrap w-full justify-between mb-3 mt-2 mt-md-0">
              <div className="relative col-span-6 md:col-span-6 !w-1/2">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    className="w-5 h-5 text-gray-500 dark:text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
                <input
                  type="text"
                  className="h-[31px] bg-white border border-gray-300 text-gray-900 text-sm rounded-[0.2rem] w-full pl-12 p-2.5 "
                  placeholder="Search"
                  value={props.searchText}
                  onChange={(e) => {
                    props?.setSearchText(e.target.value);
                  }}
                />
              </div>
              {additionalStatus && (
                <div className="ml-3 flex justify-between w-[25%]">
                  {additionalStatusValue?.inqueue > 0 && (
                    <span className="font-semibold font_pop">
                      In Queue:{additionalStatusValue?.inqueue}{" "}
                    </span>
                  )}

                  {additionalStatusValue?.purchased > 0 && (
                    <span className="font-semibold font_pop">
                      Purchased:{additionalStatusValue?.purchased}{" "}
                    </span>
                  )}

                  {additionalStatusValue?.error > 0 && (
                    <span className="font-semibold font_pop">
                      Error:{additionalStatusValue?.error}{" "}
                    </span>
                  )}

                  {additionalStatusValue?.selectedRecords > 0 && (
                    <span className="font-semibold font_pop">
                      Selected Records:{additionalStatusValue?.selectedRecords}{" "}
                    </span>
                  )}
                </div>
              )}

              {exportButton ||
              pagination !== "No" ||
              props?.exportOption?.length > 0 ? (
                <div className="w-[20%] justify-center md:justify-end gap-2 flex md:!mt-0 md:col-span-2 pr-1">
                  {exportButton && (
                    <div>
                      <button
                        onClick={exportButton}
                        text="Export"
                        className="relative float-right border ml-1 w-[119px] h-[31px] text-sm"
                      >
                        Export
                      </button>
                    </div>
                  )}
                  {props?.exportOption?.length > 0 ? (
                    <div className="relative">
                      <button
                        onClick={() => setShowExportOptions(!showExportOptions)}
                        text="Export"
                        className="relative float-right border ml-1 min-w-[119px] w-full h-[31px] text-sm"
                      >
                        Export
                        {showExportOptions && (
                          <div className="absolute w-[101.5%] !mt-1.25 bg-white z-10 border-x border-b border-[#ddd] -ml-px overflow-hidden transition ease-in-out delay-150">
                            <ul ref={optionsRef} className="!pl-0 !mb-0">
                              {props.exportOption?.map((option, index) => {
                                return (
                                  <li
                                    key={index}
                                    className="cursor-pointer px-2.5 !py-1.25 border-t border-[#ddd] hover:bg-[#e1e1e1]"
                                    onClick={() => handleExport(option.value)}
                                  >
                                    {option.label}
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        )}
                      </button>
                    </div>
                  ) : null}
                </div>
              ) : null}
              {CustomComponent ? <CustomComponent /> : null}
            </div>
          ) : null}
          {CustomComponent && searchable === "No" ? <CustomComponent /> : null}
        </div>
        <div style={{ height: height ? height : "62vh", width: "100%" }}>
          <DataGridPro
            ref={ref}
            rows={props.isLoading ? [] : items ? items : []}
            rowHeight={40}
            columnHeaderHeight={36}
            columns={columnDefs}
            pagination={pagination === "No" ? false : true}
            paginationModel={
              pagination === "No"
                ? { page: 1, pageSize: items?.length }
                : {
                    page: +paginationModel?.page - 1,
                    pageSize: +paginationModel?.pageSize,
                  }
            }
            page={pagination === "No" ? 1 : paginationModel?.page}
            pageSize={
              pagination === "No" ? items?.length : paginationModel?.pageSize
            }
            getRowClassName={getRowClass}
            rowCount={totalItems ? totalItems : 0}
            onPaginationModelChange={onPaginationModelChange}
            onPageSizeChange={onPageSizeChange}
            paginationMode={paginationMode ? paginationMode : "server"}
            components={{
              Toolbar:
                toolbar === "No"
                  ? null
                  : CustomToolbar
                  ? CustomToolbar
                  : MUIToolbar,
            }}
            loading={props.isLoading}
            componentsProps={{
              toolbar: {
                className: "bg-gray-200 dark:bg-white/10 shadow-sm",
              },
            }}
            checkboxSelection={checkboxSelection}
            getRowId={(row) =>
              getRowId
                ? row?.id
                : row?.counter
                ? row?.counter
                : row?.records?._id
                ? row?.records?._id
                : row?.id
                ? row?.id
                : row?._id
                ? row?._id
                : row?.records?.id
                ? row?.records?.id
                : row?.status
            }
            // headerHeight={40}
            classes={{
              // root: "border bg-white dark:bg-blue dark:text-white/70 px-3 mt-4",
              columnHeader: "bg-[#f8f8f8] shadow-sm",
              columnHeaderTitle: "tableHead font-bold text-md capitalize px-0",
              row: "text-md hover:bg-red-500",
            }}
            onFilterModelChange={onFilterModelChange}
            pageSizeOptions={[
              25,
              50,
              75,
              100,
              250,
              500,
              1000,
              1500,
              customSize
                ? customSize
                : totalItems
                ? {
                    label: "All",
                    value: totalItems ? totalItems : totalItems || 0,
                  }
                : 0,
            ]}
            autoHeight={autoHeight}
            getRowHeight={gridOptions}
            onRowSelectionModelChange={onRowSelectionModelChange}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={onProcessRowUpdateError}
            onCellEditStart={onCellEditStart}
            onCellEdit
            onCellEditStop={onCellEditStop}
            cellModesModel={cellModesModel}
            editMode={"row"}
            filterMode={
              filterMode
                ? filterMode
                : onFilterModelChange
                ? "server"
                : "client"
            }
            rowModesModel={rowModesModel}
            onRowModesModelChange={onRowModesModelChange}
            onRowEditStart={onRowEditStart}
            onRowEditStop={onRowEditStop}
            onRowEditCommit={onRowEditCommit}
            pinnedColumns={pinnedColumns}
            rowReordering={rowReordering}
            onRowOrderChange={handleRowOrderChange}
            treeData={treeData}
            getTreeDataPath={getTreeDataPath}
            groupingColDef={groupingColDef}
            defaultGroupingExpansionDepth={defaultGroupingExpansionDepth}
            isGroupExpandedByDefault={(node) => {
              return expendedRows?.includes(node.groupingKey);
            }}
            onRowClick={onRowClick}
            disableRowSelectionOnClick={disableRowSelectionOnClick}
            sx={sx}
            columnVisibilityModel={props.columnVisibilityModel}
            filterModel={props.filterModel}
            onSortModelChange={props.onSortModelChange}
            sortingMode={
              props.sortingMode
                ? props.sortingMode
                : props.onSortModelChange
                ? "server"
                : "client"
            }
            onColumnOrderChange={onColumnOrderChange}
            hideFooter={hideFooter}
          />
        </div>
      </div>
    </div>
  );
});

export default MUIDataTable;
