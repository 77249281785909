// selectedBatchSlice.js
import { createSlice } from "@reduxjs/toolkit";

const authUserSlice = createSlice({
  name: "selectedUser",
  initialState: {
    user: JSON.parse(localStorage.getItem("user")),
    is_browser_active: localStorage.getItem("is_browser_active") ?? null,
    is_browser_domain: localStorage.getItem("is_browser_domain") ?? null,
  },
  reducers: {
    setLoggedInUser: (state, action) => {
      state.user = action.payload;
    },
    setInstructionModal: (state, action) => {
      state.instructionModal = action.payload;
    },
    setIsBrowserActive: (state, action) => {
      state.is_browser_active = action.payload;
      localStorage.setItem("is_browser_active", action.payload);
    },
    setIsBrowserDomain: (state, action) => {
      state.is_browser_domain = action.payload;
      localStorage.setItem("is_browser_domain", action.payload);
    },
  },
});

export const {
  setLoggedInUser,
  setInstructionModal,
  setIsBrowserActive,
  setIsBrowserDomain,
} = authUserSlice.actions;
export default authUserSlice.reducer;
