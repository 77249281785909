import React, { useState } from "react";
import { DataTable } from "../../components";
import {
  ClipboardDocumentIcon,
  EyeIcon,
  EyeSlashIcon,
} from "@heroicons/react/24/solid";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dayjs from "dayjs";

export default function DomainReport({ domainReport }) {
  const [showPassword, setShowPassword] = useState(false);

  const handleCopyPassword = (password) => {
    navigator.clipboard
      .writeText(password)
      .then(() => {
        toast.success("password copied", { autoClose: 1000 });
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const handleTogglePassword = (index) => {
    setShowPassword((prevShowPassword) => {
      if (prevShowPassword === index) {
        return null;
      } else {
        return index;
      }
    });
  };

  return (
    <DataTable
      className="px-0 py-0"
      heads={[
        "sr#",
        "username",
        "password",
        "backlink domain",
        "group name",
        "backlink url",
        "created at",
        "status",
        "content",
      ]}
      items={domainReport?.map((item, index) => {
        const isPasswordVisible = showPassword === index;
        return {
          serialNumber: index + 1,
          username: item["username"],
          password: (
            <div className="flex items-center text-gray-600 gap-2">
              <p className="w-16 overflow-hidden">
                {isPasswordVisible ? item["password"] : "●●●●●●●"}
              </p>
              <button type="button" onClick={() => handleTogglePassword(index)}>
                {isPasswordVisible ? (
                  <EyeIcon
                    title="hide password"
                    className="h-4 cursor-pointer"
                  />
                ) : (
                  <EyeSlashIcon
                    title="show password"
                    className="h-4 cursor-pointer"
                  />
                )}
              </button>

              <ClipboardDocumentIcon
                title="copy password"
                onClick={() => handleCopyPassword(item["password"])}
                className="h-4 cursor-pointer"
              />
            </div>
          ),
          backlink_domain: item["backlink_domain"],
          group_name: item["group_name"],
          backlink_content_url: item["backlink_content_url"],
          created_at: dayjs(item["created_at"]).format(
            "ddd, MMM D, YYYY h:mm A"
          ),
          status: item["status"],
          content: item["content"],
        };
      })}
    />
  );
}
