import { InputField } from "../../../components";
import FormCheckbox from "../../../components/common/CheckBox";
import { Dropdown } from "primereact/dropdown";

export const operators = {
  string: [
    {
      value: "eq",
      label: "Equal to",
      type: "string",
      InputComponent: (props) => <InputField {...props} />,
    },
    {
      value: "not",
      label: "Not equal to",
      type: "string",
      InputComponent: (props) => <InputField {...props} />,
    },
    {
      value: "cn",
      label: "Contains",
      type: "string",
      InputComponent: (props) => <InputField {...props} />,
    },
    // {
    //   value: "not_contain",
    //   label: "Not Contains",
    //   type: "string",
    //   InputComponent: (props) => <InputField {...props} />,
    // },
    {
      value: "startsWith",
      label: "Starts With",
      type: "string",
      InputComponent: (props) => <InputField {...props} />,
    },
    {
      value: "endsWith",
      label: "Does not start with",
      InputComponent: (props) => <InputField {...props} />,
    },
  ],
  number: [
    {
      value: "equal_to",
      label: "Equals",
      InputComponent: (props) => <InputField type="number" {...props} />,
    },
    {
      value: "not_equal_to",
      label: "Not equal to",
      InputComponent: (props) => <InputField type="number" {...props} />,
    },
    {
      value: "lessThan",
      label: "Less Than",
      InputComponent: (props) => <InputField type="number" {...props} />,
    },
    {
      value: "lessThanOrEqualTo",
      label: "Less than or equal to",
      InputComponent: (props) => <InputField type="number" {...props} />,
    },
    {
      value: "greaterThan",
      label: "Greater Than",
      InputComponent: (props) => <InputField type="number" {...props} />,
    },
    {
      value: "greaterThanOrEqualTo",
      label: "Greater than or equal to",
      InputComponent: (props) => <InputField type="number" {...props} />,
    },
  /*   {
      value: "between",
      label: "Between",
      InputComponent: (props) => (
        <>
          <InputField type="number" {...props} placeholder="From" />
          <InputField type="number" {...props} placeholder="To" />
        </>
      ),
    }, */
  ],
  date: [
    {
      value: "equal_to",
      label: "Equals",
      InputComponent: (props) => <InputField type="date" {...props} />,
    },
    {
      value: "not_equal_to",
      label: "Not equal to",
      InputComponent: (props) => <InputField type="date" {...props} />,
    },
    {
      value: "lessThan",
      label: "Less Than",
      InputComponent: (props) => <InputField type="date" {...props} />,
    },
    {
      value: "lessThanOrEqualTo",
      label: "Less than or equal to",
      InputComponent: (props) => <InputField type="date" {...props} />,
    },
    {
      value: "greaterThan",
      label: "Greater Than",
      InputComponent: (props) => <InputField type="date" {...props} />,
    },
    {
      value: "greaterThanOrEqualTo",
      label: "Greater than or equal to",
      InputComponent: (props) => <InputField type="date" {...props} />,
    },
   /*  {
      value: "within",
      label: "Within",
      InputComponent: (props) => (
        <>
          <InputField type="date" {...props} placeholder="From" />
          <InputField type="date" {...props} placeholder="To" />
        </>
      ),
    },
    {
      value: "notWithin",
      label: "Not within",
      InputComponent: (props) => (
        <>
          <InputField type="date" {...props} placeholder="From" />
          <InputField type="date" {...props} placeholder="To" />
        </>
      ),
    }, */
    {
      value: "matches",
      label: "Matches",
      InputComponent: (props) => <InputField type="date" {...props} />,
    },
    /*
    {
      value: "before",
      label: "Before",
      InputComponent: (props) => <InputField type="date" {...props} />,
    },
    {
      value: "after",
      label: "After",
      InputComponent: (props) => <InputField type="date" {...props} />,
    },
    {
      value: "between",
      label: "Between",
      InputComponent: (props) => (
        <>
          <InputField type="date" {...props} placeholder="From" />
          <InputField type="date" {...props} placeholder="To" />
        </>
      ),
    }, */
  ],
  boolean: [
    {
      value: "isTrue",
      label: "Is True",
      InputComponent: (props) => <FormCheckbox checked {...props} />,
    },
    {
      value: "isFalse",
      label: "Is False",
      InputComponent: (props) => <FormCheckbox {...props} />,
    },
  ],
  select: [
    {
      value: "eq",
      label: "is",
      InputComponent: (props) => <Dropdown {...props} />,
    },
    {
      value: "not",
      label: "is not",
      InputComponent: (props) => <Dropdown {...props} />,
    },
    // {
    //   value: "contains",
    //   label: "Contains",
    //   type: "multiSelect",
    //   InputComponent: (props) => <Dropdown {...props} />,
    //   /* InputComponent: (props) => (
    //     <MultiSelect
    //       {...props}
    //       maxSelectedLabels={2}
    //       closeOnSelect={false}
    //       filter
    //     />
    //   ), */
    // },
    // {
    //   value: "not_contain",
    //   label: "Does not contain",
    //   type: "multiSelect",
    //   InputComponent: (props) => <Dropdown {...props} />,
    //  /*  InputComponent: (props) => (
    //     <MultiSelect
    //       {...props}
    //       maxSelectedLabels={2}
    //       closeOnSelect={false}
    //       filter
    //     />
    //   ), */
    // },
  ],
};
