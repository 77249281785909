import React from "react";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { DataGridPro } from "@mui/x-data-grid-pro";
import dayjs from "dayjs";
function AssignBacklinkDetailModal({ isAssignDetail, onClose }) {
  const columnsDef = [
    { headerName: "#", field: "counter", width: 80 },
    {
      headerName: "Backlink",
      field: "backlink_domain",
      flex: 1,
      minWidth: 200,
    },
    {
      headerName: "Batch Number",
      field: "batch_number",
      flex: 1,
      minWidth: 200,
    },
    {
      headerName: "Assign To User",
      field: "assigned_to",
      flex: 1,
      minWidth: 200,
    },
    {
      headerName: "Assigned Date",
      field: "assigned_date",
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "Task Status",
      field: "task_status",
      flex: 1,
      minWidth: 150,
    },
  ];

  function generateRandomId() {
    // Generates a random alphanumeric string
    return Math.random().toString(36).substring(7);
  }
  return (
    <div className="w-full p-6 bg-white fixed top-0 left-0 h-screen z-50">
      <div className="w-full flex flex-col md:flex-row items-center gap-5 justify-between mb-4 px-3">
        <h2 className="elementHeading3 capitalize">Assign Backlinks List</h2>
        <div className="flex items-center justify-end w-full md:w-auto">
          <div className="flex items-center end">
            <XCircleIcon
              className="text-red-500 w-8 cursor-pointer"
              onClick={onClose}
            />
          </div>
        </div>
      </div>
      <div className="h-[calc(100vh-90px)]">
        <DataGridPro
          rows={
            isAssignDetail?.backlinks?.map((item, index) => ({
              ...item,
              assigned_to: item?.assigned_to || "Not Assigned",
              assigned_date: item.assigned_date
                ? dayjs(item?.assigned_date).format("ddd, MMM D, YYYY h:mm A")
                : "Not Assigned",
              counter: index + 1,
            })) || []
          }
          getRowId={() => generateRandomId()}
          columns={columnsDef}
          paginationMode="client"
          classes={{
            root: "border-none bg-white dark:bg-blue dark:text-white/70 px-3 mt-4",
            columnHeader: "bg-gray-200 dark:bg-white/10 shadow-sm",
            columnHeaderTitle: "tableHead font-bold text-md capitalize px-0",
            row: "text-md hover:bg-red-500",
          }}
        />
      </div>
    </div>
  );
}

export default AssignBacklinkDetailModal;
