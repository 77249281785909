import React from "react";
import { BsFillReplyFill, BsFillPersonFill } from "react-icons/bs";
import { FaReply } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import dayjs from "dayjs";
const InboxDetail = () => {
  const location = useLocation();
  const selectedEmail = location?.state?.selectedEmail;
  const renderContent = (content) => {
    if (!content) {
      return "";
    }
    // Check if the content is HTML
    const isHTML = /<\/?[a-z][\s\S]*>/i.test(content);
    if (isHTML) {
      return <div dangerouslySetInnerHTML={{ __html: content }} />;
    }
    // Default to plain text
    return <pre>{content}</pre>;
  };

  return (
    <div className="p-5 px-2 lg:px-5">
      <div className="h-ful p-4 bg-neutral-1 overflow-x-auto bg-white border">
        <Link to={"/mailbox"} className="font-semibold flex items-center">
          <FaReply className="mt- mr-1" /> Back
        </Link>
        <div className="mt-4">
          <div className="h-full p-4 bg-neutral-100">
            <div className="flex justify-between items-center">
              <h3>
                {selectedEmail && selectedEmail.subject
                  ? selectedEmail.subject
                  : ""}
              </h3>
              <div className="flex gap-2">
                <span>
                  {" "}
                  {selectedEmail && selectedEmail.time
                    ? dayjs(selectedEmail.time).format(
                        "ddd, MMM D, YYYY h:mm A"
                      )
                    : ""}
                </span>
                <button>
                  <BsFillReplyFill />
                </button>
              </div>
            </div>
            <div className="flex gap-3 mt-3">
              <div className="bg-neutral-500 w-[25px] h-[25px] rounded-full text-white flex justify-center items-center">
                <BsFillPersonFill />
              </div>
              <h5>
                {" "}
                {selectedEmail && selectedEmail.from ? selectedEmail.from : ""}
              </h5>
            </div>
            <div className="p-2 mt-3">
              <p className="overflow-y-scroll calc(100vh-100px) ">
                {renderContent(selectedEmail.mailcontent)}
                {/* {selectedEmail && selectedEmail.mailcontent
                ? selectedEmail.mailcontent
                : ""} */}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default InboxDetail;
