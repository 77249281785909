import React from "react";

export default function ToolTip({ tool, tip, className, arrowStyle }) {
  return (
    <div className="navDropdownButton relative">
      <p>{tool}</p>
      {tip && (
        <div
          className={`navDropdown mt-9 top-0 left-1/2 transform -translate-x-1/2 flex-col items-center z-10 w-[200px] absolute ${className}`}
        >
          <Triangle arrowStyle={arrowStyle} />
          <p
            style={{ fontSize: "12px" }}
            className="bg-[#1E40AF] py-2 px-4 shadow-xl shadow-black/20 text-white rounded normal-case font-medium max-w-3xl whitespace-pre-wrap text-center"
          >
            {tip}
          </p>
        </div>
      )}
    </div>
  );
}

function Triangle({ arrowStyle }) {
  const triangleStyles = {
    width: "0",
    height: "0",
    borderBottom: "10px solid #1E40AF", // Replace 'border-blue-900' with the desired color value
    borderLeft: "5px solid transparent",
    borderRight: "5px solid transparent",
  };

  return <div className={`${arrowStyle}`} style={triangleStyles}></div>;
}
