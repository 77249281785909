import React, { useEffect, useState } from "react";
import { DataTable } from "../../components";
import api from "../../services/api";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSelectedGroup } from "../../store/slices/selectedGroupSlice";

export default function GroupListHasBatch() {
  const { user } = useParams();
  const [users, setUsers] = useState([]);
  const [myUser, setMyUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [groupListBatch, setGroupListBatch] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    api
      .get(`${process.env.REACT_APP_PUBLIC_API}/api/users`)
      .then((res) => {
        setUsers(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const fetchGroupListHasBatch = () => {
    api
      .post(
        `${process.env.REACT_APP_PUBLIC_API}/api/groupBatches/groupNameListHasBatch/`,
        {
          user_id: myUser.id,
          level: myUser.role_id.level,
        }
      )
      .then((response) => {
        setGroupListBatch(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const findMyUser = () => {
    const foundUser = users?.users?.find((item) => item.name === user);
    if (foundUser) {
      setMyUser(foundUser);
    }
  };

  useEffect(() => {
    if (users?.totalCount > 0) {
      findMyUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]); // Include users as a dependency

  useEffect(() => {
    if (myUser?.name) {
      fetchGroupListHasBatch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myUser]); // Include myUser as a dependency

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleBatchesList = (group) => {
    dispatch(setSelectedGroup(group));
    navigate(`/users/${myUser?.name}/${group?.group_name}`);
  };

  return (
    <div className="p-7">
      <DataTable
        searchBox
        title={`Group List of ${user}`}
        isLoading={isLoading}
        heads={[
          "id",
          "group",
          "assigned batch",
          "free batch",
          "total batch",
          "action",
        ]}
        items={groupListBatch?.groupList?.map((group, index) => ({
          serialNumber: index + 1,
          group: group.group_name,
          assignedBatch: group.total_assigned_batch,
          free_batch: group.free_batch,
          total_batch: group.total_batch,
          action: (
            <button
              onClick={() => handleBatchesList(group)}
              className="btnPrimary"
            >
              Batches List
            </button>
          ),
        }))}
      />
    </div>
  );
}
