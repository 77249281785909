import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid";
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { DataTable } from "../../components";
import api from "../../services/api";
import AddTabelRows from "./AddUpdateUserForm";
import ImportModal from "./ImportModal";
import userPermission from "../../utils/userPermission";
import Loading from "../../components/Loader/Loading";

function Templates({ searchText }) {
  const [industryId, setIndustryId] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [isImport, setIsImport] = useState(true);
  const [recordForm, setRecordForm] = useState(true);
  const [editingRecord, setEditingRecord] = useState();
  const [selectedData, setSelectedData] = useState([]);
  const paginationModel = {
    pageSize: 100,
    page: 1,
  };
  const fetchTemplates = (payload) => {
    setIsLoading(true);
    setSelectedData([]);
    api
      .get(`/api/backlinks/tableRows`, { params: payload })
      .then((response) => {
        setTemplates(response.data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      })
      .finally(() => setIsLoading(false));
  };
  const fetchIndustries = () => {
    setIsLoading(true);
    api
      .get(`/api/domains/allIndustry`)
      .then((response) => {
        setIndustries(response.data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      })
      .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    fetchTemplates({
      page: paginationModel?.page,
      perPage: paginationModel?.pageSize,
      industry_id: industryId,
    });
    fetchIndustries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleEdit = (record) => {
    setEditingRecord(record);
    setRecordForm(!recordForm);
  };
  const data = templates?.data?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] && e[key] !== "industry_id" && e[key] && e[key] !== "id" /* &&
      e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase()) */
    );
  });
  const selectAll = () => {
    let id = [];
    if (selectedData?.length !== templates?.data?.length) {
      // eslint-disable-next-line
      templates?.data?.map((val) => {
        id = [...id, val];
      });
      setSelectedData(id);
    } else if (selectedData?.length === templates?.data?.length) {
      setSelectedData([]);
    }
  };
  const onSingleselect = (val) => {
    if (selectedData?.length > 0) {
      if (selectedData.filter(({ id }) => id === val.id).length > 0) {
        const arr = selectedData.filter(({ id }) => id !== +val.id);
        setSelectedData(arr);
      } else {
        setSelectedData([...selectedData, val]);
      }
    } else {
      setSelectedData([...selectedData, val]);
    }
  };

  const isChecked = (val) => {
    if (selectedData?.length > 0) {
      if (selectedData?.filter(({ id }) => id === val).length > 0) {
        return true;
      }
    }
    return false;
  };
  const deleteRecordHandler = (data) => async () => {
    const c = window.confirm("Are you sure to perform this action?");
    let ids = [];
    // eslint-disable-next-line
    data.map((val) => {
      ids = [...ids, val.id];
    });

    if (c) {
      setIsLoading(true);
      api
        .post(
          `${process.env.REACT_APP_PUBLIC_API}/api//backlinks/deleteTableRows`,
          { id: ids }
        )
        .then((response) => {
          toast.success("Table Rows delete successfully");
          fetchTemplates({
            page: paginationModel?.page,
            perPage: paginationModel?.pageSize,
          });
        })
        .catch((error) => {
          console.log(error);
          toast.error("Table Rows couldn't be delete");
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };
  const exportTableRows = () => {
    setIsLoading(true);
    api
      .get(`${process.env.REACT_APP_PUBLIC_API}/api/backlinks/exporTable`, {
        params: { industry_id: industryId },
      })
      .then((response) => {
        window.open(response?.data?.path, "_blank")?.focus();
        toast.success("Table Rows export successfully");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Table Rows couldn't be export");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="px-6 pt-7 relative">
      {!isImport && (
        <ImportModal
          isImport={isImport}
          setOpen={setIsImport}
          fetchTemplates={fetchTemplates}
          industries={industries}
          selectedIndustry={industryId}
        />
      )}
      {!recordForm && (
        <AddTabelRows
          isImport={recordForm}
          setOpen={setRecordForm}
          industries={industries}
          fetchTemplates={fetchTemplates}
          editingRecord={editingRecord}
          selectedIndustry={industryId}
        />
      )}
      <ToastContainer />
      {isLoading && <Loading />}
      <div className="w-full relative">
        <div className="flex items-center justify-end absolute w-full">
          {/* Backlinks Filters */}
          <div className="flex flex-row">
            <select
              className="bg-white dark:bg-dark p-2 boder-solid border w-fit border-slate-300 rounded mr-1"
              value={industryId}
              onChange={(e) => {
                setIndustryId(e.target.value);
                fetchTemplates({
                  page: paginationModel?.page,
                  perPage: paginationModel?.pageSize,
                  industry_id: e.target.value,
                });
              }}
              name="industry_id"
              // className={`inputField`}
              // error={errors.industry_id}
            >
              <option value={0}>Common</option>
              {industries?.map(({ id, industry_name }, index) => (
                <option key={index} value={id}>
                  {industry_name}
                </option>
              ))}
            </select>
            {userPermission("Export") ? (
              <button
                className="flex items-center gap-2 btnSecondary mr-1"
                onClick={exportTableRows}
              >
                Export
              </button>
            ) : null}
            {userPermission("Import") ? (
              <button
                className="flex items-center gap-2 btnSecondary mr-1"
                onClick={() => setIsImport(!isImport)}
              >
                Import
              </button>
            ) : null}
            {userPermission("Delete") ? (
              <button
                className="flex items-center gap-2 btnSecondary mr-1"
                onClick={deleteRecordHandler(selectedData)}
                disabled={selectedData?.length > 0 ? false : true}
              >
                Delete
              </button>
            ) : null}
            {userPermission("Add") ? (
              <button
                className="flex items-center gap-2 btnSecondary mr-1"
                onClick={() => setRecordForm(!recordForm)}
              >
                Add
              </button>
            ) : null}
          </div>
        </div>
      </div>
      {/* {data?.length > 0 ? ( */}
      <DataTable
        searchBox
        totalRecords={templates.totalItems}
        tdClass={"!whitespace-normal"}
        heads={[
          <input
            type="checkbox"
            onChange={selectAll}
            checked={
              data?.length > 0 && selectedData?.length === data?.length
                ? true
                : false
            }
          />,
          "Sr.",
          "Type",
          "Industry",
          "VTag",
          "Tag Name",
          "Text",
          "Action",
        ]}
        items={data?.map((user, index) => ({
          checkBox: (
            <input
              type="checkbox"
              className="cursor-pointer"
              checked={isChecked(user.id)}
              onChange={() => onSingleselect(user)}
            />
          ),
          serialnumber: index + 1,
          type: user?.type,
          industry_id: industries?.find(({ id }) => id === user?.industry_id)
            ?.industry_name,
          v_tag: user?.v_tag,
          tag_name: user?.tag_name,
          text: user?.text,
          action:
            selectedData?.filter((item) => item.id === user.id).length >
            0 ? null : (
              <span className="h-full w-full flex items-center justify-center px-3">
                <PencilSquareIcon
                  size={20}
                  className="h-4 cursor-pointer text-gray-400 hover:text-yellow-500"
                  onClick={() => handleEdit(user)}
                />
                <TrashIcon
                  size={22}
                  className="h-4 cursor-pointer text-gray-400 hover:text-red-400"
                  onClick={deleteRecordHandler([user])}
                />
              </span>
            ),
        }))}
      />

      {/* ) : null} */}
      {/* {data?.length > 0 ? (
    <DataTable
        isLoading={isLoading}
        totalRecords={templates.totalItems}
        heads={templates?.tableColumns}
        items={templates.data
            ?.map((user, index) => {
              const mappedItem = { serialnumber: index + 1 };

              Object.keys(user).forEach((key) => {
                mappedItem[key] = user[key];
              });

              mappedItem.action = (
                <div className="flex items-center justify-center">
                  <PencilSquareIcon
                    key={`${user.username}-action`}
                    className="h-4 cursor-pointer text-gray-400"
                    // onClick={() => handleEditUser(user)}
                  />
                </div>
              );

              return mappedItem;
            })}
      />
):null} */}
    </div>
  );
}

export default Templates;
