// selectedBatchSlice.js
import { createSlice } from "@reduxjs/toolkit";

const selectedBatchesSlice = createSlice({
  name: "selectedBatches",
  initialState: [],
  reducers: {
    setSelectedBatches: (state, action) => {
      return action.payload;
    },
    removeDomainFromBatch: (state, action) => {
      const { batchIndex, domainIndex } = action.payload;
      state[batchIndex].domains.splice(domainIndex, 1);
    },
  },
});

export const { setSelectedBatches, removeDomainFromBatch } =
  selectedBatchesSlice.actions;
export default selectedBatchesSlice.reducer;
