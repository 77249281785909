import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import api from "../../services/api";
import MUIDataTable from "../../components/MUIGrid";
import { Button, Tooltip, TablePagination } from "@mui/material";
import { FaSearch } from "react-icons/fa";
import Loading from "../../components/Loader/Loading";
import { Modal } from "../../components";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import BacklinkAgreeForm from "./BacklinkAgreeForm";
import CustomBreadcrumbs from "../../components/common/CustomBreadcrumbs";

export default function AssignBacklinks() {
  const location = useLocation();
  const selectedDomains = location?.state?.selectedDomains;
  const [users, setUsers] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [user, setUser] = useState("");
  const [backlinksData, setBacklinksData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [backlinksTypeList, setBacklinksTypeList] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [modalType, setModalType] = useState("");
  const [agreeData, setAgreeData] = useState("");
  const [isConfirmed, setIsConfirmed] = useState(false);
  const navigate = useNavigate();
  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "",
    rules: [],
  });
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 1,
  });
  const [backlinksStatusList, setBacklinksStatusList] = useState([]);
  const followStatus = [
    { value: "Nofollow", label: "Nofollow" },
    { value: "Dofollow", label: "Dofollow" },
    { value: "Pending", label: "Pending" },
  ];
  const get_user = async () => {
    const filters = {
      groupOp: "AND",
      rules: [
        { field: "is_active", op: "eq", data: 1 },
        { field: "role_id", op: "isAnyOf", data: [3, 8] },
      ],
    };
    try {
      const res = await api.post("/api/users/users_report", {
        filters,
        page: 1,
        perPage: 1000,
      });
      if (res.status === 200) {
        setUsers(res.data?.records);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("🚀 ~ getSERPReport ~ err:", err);
    }
  };
  const fetchBacklinksData = async (payload) => {
    setIsLoading(true);
    setBacklinksData([]);
    api
      .post(`${process.env.REACT_APP_PUBLIC_API}/api/backlinks/`, payload)
      .then((response) => {
        setBacklinksData(response?.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (selectedDomains?.length === 0) {
      navigate("/domains");
    }
    get_user();
    fetchBacklinksData({
      page: paginationModel?.page,
      perPage: paginationModel?.pageSize,
      filter: queryOptions,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  const getBacklinkStatusList = () => {
    setIsLoading(true);
    api
      .get(`/api/backlinks/backlink_status_list`)
      .then((res) => setBacklinksStatusList(res.data))
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  //   Get Type List
  const getBacklinkTypeList = () => {
    setIsLoading(true);
    api
      .get(`/api/backlinks/backlink_type_list`)
      .then((res) => setBacklinksTypeList(res.data))
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getBacklinkTypeList();
    getBacklinkStatusList();
  }, []);

  const onSelectAll = (checked) => {
    if (checked) {
      const updatedArray = backlinksData?.backlinksData?.map((item) => item);
      setSelectedData(updatedArray);
    } else {
      setSelectedData([]);
    }
  };

  const isSelected = (domain_id) => {
    if (selectedData?.length > 0) {
      if (
        selectedData?.filter((item) => item["b.id"] === domain_id).length > 0
      ) {
        return true;
      }
    }
    return false;
  };

  const onSingleSelect = ({ checked, data }) => {
    if (checked) {
      setSelectedData([...selectedData, data]);
    } else {
      setSelectedData(
        selectedData?.filter((item) => item["b.id"] !== data["b.id"])
      );
    }
  };

  const columnDefs = [
    {
      field: "checkbox",
      renderHeader: (params) => (
        <input
          type="checkbox"
          onChange={(e) => onSelectAll(e.target.checked)}
          className={`form-checkbox h-5 w-5 text-[#6366F1] roundd focus:ring-0 cursor-pointer mr-2`}
          checked={
            backlinksData?.backlinksData?.length &&
            backlinksData?.backlinksData?.length === selectedData?.length
          }
        />
      ),
      filterable: false,
      sortable: false,
      minWidth: 30,
      disableColumnMenu: true,
      renderCell: (params) => (
        <input
          type="checkbox"
          checked={
            params.row.records.population_status ||
            isSelected(params.row.records["b.id"])
          }
          onChange={(e) =>
            onSingleSelect({
              checked: e.target.checked,
              data: params.row.records,
            })
          }
          className={`form-checkbox h-5 w-5 text-[#6366F1] roundd focus:ring-0 cursor-pointer mr-2`}
        />
      ),
    },
    { field: "counter", headerName: "Sr#", filterable: false, width: 60 },
    {
      field: "b.backlink_domain",
      headerName: "Backlink Domain",
      minWidth: 180,
      editable: true,
      renderCell: (params) => {
        return (
          <a
            href={`https://www.${params.row["b.backlink_domain"]}`}
            className="hover:text-secondary underline"
            target="_blank"
            rel="noreferrer"
          >
            {params.row["b.backlink_domain"]}
          </a>
        );
      },
    },
    {
      field: "b.ready_status_id",
      headerName: "Backlink Ready Status",
      minWidth: 50,
      flex: 1,
      editable: true,
      filterable: true,
      type: "singleSelect",
      getOptionValue: (value) => value?.id,
      getOptionLabel: (value) => value.ready_status,
      valueOptions: backlinksStatusList,
      renderCell: (params) => params.value["b.ready_status"],
      valueGetter: (params) => params.row["b.ready_status"],
      valueFormatter: (params) => params.value,
    },
    {
      field: "b.follow_status",
      headerName: "Follow Status",
      minWidth: 50,
      flex: 1,
      editable: true,
      filterable: true,
      type: "singleSelect",
      getOptionValue: (value) => value?.value,
      getOptionLabel: (value) => value.label,
      valueOptions: followStatus,
      renderCell: (params) => params.value["b.follow_status"],
      valueGetter: (params) => params.row["b.follow_status"],
      valueFormatter: (params) => params.value,
    },
    {
      field: "b.backlink_type_id",
      headerName: "Backlink Type",
      minWidth: 150,
      editable: true,
      align: "left",
      type: "singleSelect",
      getOptionValue: (value) => value?.id,
      getOptionLabel: (value) => value.type,
      valueOptions: backlinksTypeList,
      renderCell: (params) => params.value["b.type"],
      valueGetter: (params) => params.row["b.type"],
      valueFormatter: (params) => params.value,
    },
    {
      field: "b.traffic",
      headerName: "Traffic",
      minWidth: 150,
      editable: true,
      align: "left",
      filterable: false,
    },
    {
      field: "b.domain_rating",
      headerName: "DR",
      width: 100,
      editable: true,
      filterable: false,
      renderHeader: (params) => (
        <div className="flex justify- items-center w-full">
          <Tooltip
            title={
              <div className="flex flex-col text-sm w-[full]">
                Domain Rating
              </div>
            }
          >
            <span>DR</span>
          </Tooltip>
        </div>
      ),
    },
  ];
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    fetchBacklinksData({
      filter: queryOptions,
      page: +params.page + 1,
      perPage: params.pageSize,
    });
  };
  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    fetchBacklinksData({
      filter: queryOptions,
      page: 1,
      perPage: paginationModel.pageSize,
    });
  };
  const onFilterChange = React.useCallback((filterModel) => {
    let ruless = [];
    // eslint-disable-next-line array-callback-return
    filterModel.items?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `${rule.field}`,
          op:
            rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator === "="
              ? "eq"
              : rule.operator === "!="
              ? "not"
              : rule.operator === ">"
              ? "gt"
              : rule.operator === ">="
              ? "gte"
              : rule.operator === "<"
              ? "lt"
              : rule.operator === "<="
              ? "lte"
              : rule.operator,
          // data: rule.value ? rule.value : null,
          data:
            rule.field === "latest_position"
              ? +rule.value
              : rule.value
              ? rule.value
              : rule.value === 0
              ? rule.value
              : null,
        },
      ];
    });
    // ?.toLowerCase()?.includes(searchInput?.toLowerCase())
    setQueryOptions({
      groupOp: filterModel.logicOperator.toUpperCase(),
      rules: ruless,
    });
  }, []);
  const offset = (paginationModel?.page - 1) * paginationModel?.pageSize;
  // Table Tool Bar
  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer className="flex items-center space-x-1 my-0 justify-between">
        <div>
          <GridToolbarColumnsButton
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
            }}
          />
          <GridToolbarDensitySelector
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
            }}
          />

          <GridToolbarFilterButton
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              fontSize: "0.8125rem",
            }}
            ref={setFilterButtonEl}
          />
          <Button
            variant="text"
            onClick={handleNewFilter}
            startIcon={<FaSearch size={16} />}
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              fontSize: "0.8125rem",
              paddingLeft: 1,
              paddingRight: 1,
            }}
            // className="!text-[#042a42]"
          >
            Apply filter
          </Button>
          {selectedData?.length > 0 ? (
            <Button
              variant="text"
              sx={{
                borderRadius: "6px",
                marginRight: "4px",
                border: "1px solid",
                borderColor: "#e8eaee",
                height: "26px",
                fontSize: "0.8125rem",
                paddingLeft: 1,
                paddingRight: 1,
              }}
            >
              Total Selected Backlinks
              <small
                className={`flex w-${Math.max(
                  selectedData?.length.toString().length * 2 + 2,
                  4
                )} h-${Math.max(
                  selectedData?.length.toString().length * 2 + 2,
                  4
                )} p-0.5 rounded-full bg-secondary mb-0 ml-1 items-center justify-center text-white text-xs`}
              >
                {selectedData?.length}
              </small>
            </Button>
          ) : null}
        </div>
        <TablePagination
          color="primary"
          shape="rounded"
          size="medium"
          showFirstButton
          showLastButton
          count={
            backlinksData?.totalCount
              ? backlinksData?.totalCount
              : backlinksData?.backlinksData?.length || 0
          }
          page={paginationModel.page - 1 || 0}
          onPageChange={(e, page) => handleTopPageChange(page)}
          rowsPerPage={paginationModel.pageSize || 100}
          onRowsPerPageChange={(e) => handleTopPageSizeChange(e.target.value)}
          component="div"
          rowsPerPageOptions={[
            25,
            50,
            75,
            100,
            250,
            500,
            1000,
            1500,
            2000,
            {
              label: "All",
              value: backlinksData?.totalCount
                ? backlinksData?.totalCount
                : backlinksData?.backlinksData?.length || 0,
            },
          ]}
        />
      </GridToolbarContainer>
    );
  }
  const handleTopPageChange = (page) => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: +page + 1 });
    fetchBacklinksData({
      filter: queryOptions,
      page: +page + 1,
      perPage: paginationModel.pageSize,
    });
  };
  const handleTopPageSizeChange = (newPageSize) => {
    setPaginationModel({ page: 1, pageSize: newPageSize });
    fetchBacklinksData({
      filter: queryOptions,
      page: 1,
      perPage: newPageSize,
    });
  };
  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);
  const handleModal = () => {
    setOpen(!open);
  };

  const handleModalCancel = () => {
    handleModal(false);
    fetchBacklinksData({
      page: paginationModel?.page,
      perPage: paginationModel?.pageSize,
      filters: queryOptions,
    });
    setUser("");
    setSelectedData([]);
  };
  const handleAssignBacklink = async (param) => {
    setIsLoading(true);
    try {
      const saveDataArr = {
        domains: selectedDomains?.map((item) => item["domain"]),
        user_id: parseInt(user),
        is_confirmed: param ? param : isConfirmed,
        backlink_ids: selectedData?.map((item) => item["b.id"]),
      };
      const res = await api.post("api/domains/assign_backlinks", saveDataArr);
      if (res.status === 201) {
        toast.success("Backlinks assigned successfully");
        handleModal(false);
        fetchBacklinksData({
          page: paginationModel?.page,
          perPage: paginationModel?.pageSize,
          filters: queryOptions,
        });
        setUser("");
        setSelectedData([]);
        setTimeout(() => {
          navigate("/batches");
        }, 1000);
      } else {
        toast.error(res.message);
      }
    } catch (err) {
      if (
        err?.response?.data?.availableToCreate ||
        err?.response?.data?.alreadyCreatedtasks
      ) {
        setModalType("agreeModal");
        setAgreeData(err.response.data);
        handleModal();
      } else
        toast.error(
          err.response?.data?.message || "Failed to assign backlinks!"
        );
    } finally {
      setIsLoading(null);
    }
  };
  const assignBatchToBacklink = async (param) => {
    setIsLoading(true);
    try {
      const saveDataArr = {
        batch_id: location?.state?.selectedBatch,
        is_confirmed: param ? param : isConfirmed,
        backlink_ids: selectedData?.map((item) => item["b.id"]),
      };
      const res = await api.post(
        "api/domains/assign_backlinks_in_existing_batch",
        saveDataArr
      );
      if (res.status === 201) {
        toast.success("Backlinks assigned successfully");
        handleModal(false);
        fetchBacklinksData({
          page: paginationModel?.page,
          perPage: paginationModel?.pageSize,
          filters: queryOptions,
        });
        setUser("");
        setSelectedData([]);
        setTimeout(() => {
          navigate("/batches");
        }, 1000);
      } else {
        toast.error(res.message);
      }
    } catch (err) {
      if (
        err?.response?.data?.availableToCreate ||
        err?.response?.data?.alreadyCreatedtasks
      ) {
        setModalType("agreeModal");
        setAgreeData(err.response.data);
        handleModal();
      } else
        toast.error(
          err.response?.data?.message || "Failed to assign backlinks!"
        );
    } finally {
      setIsLoading(null);
    }
  };
  return (
    <div className="px-6 pt-4">
      <CustomBreadcrumbs
        crumbs={[
          { label: "Home", link: "/" },
          { label: "Domains ", link: "/domains" },
          { label: "Assign backlinks ", link: "" },
        ]}
      />
      {isLoading && <Loading />}
      <ToastContainer />
      {!location?.state?.selectedBatch ? (
        <div className="mb-2 pr-2">
          <div className="flex items-end justify-between mt-4  dark:text-white pr-2">
            <div className="flex flex-row items-center w-full space-x-2">
              <h2 className="font-bold text-m">
                Assign backlinks to selected {selectedDomains?.length || 0}{" "}
                domains
              </h2>
              <select
                onChange={(e) => setUser(e.target.value)}
                value={user}
                name="user_id"
                className="inputField w-[300px] h-[40px] text-black p-2 dark:bg-transparent dark:border-white/30 dark:text-white cursor-pointer rounded-md"
              >
                <option value="" className="dark:bg-blue">
                  Select User
                </option>
                {users.map((item, index) => (
                  <option key={index} value={item?.id} className="dark:bg-blue">
                    {item?.name.replace(/\b\w/g, (c) => c.toUpperCase())}{" "}
                    {item?.role ? `( ${item?.role} )` : ""}{" "}
                    {item?.pending_tasks ? item?.pending_tasks : ""}
                  </option>
                ))}
              </select>
              <button
                onClick={() => handleAssignBacklink()}
                className="btnPrimary bg-green-600"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      ) : (
        <>
          {selectedData?.length ? (
            <div className="mb-2 pr-2">
              <div className="flex items-end justify-between mt-4  dark:text-white pr-2">
                <div className="flex flex-row items-center w-full space-x-2">
                  <h2 className="font-bold text-m">
                    {selectedData?.length} more backlink added
                  </h2>
                  <button
                    onClick={() => assignBatchToBacklink()}
                    className="btnPrimary bg-green-600"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </>
      )}
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={backlinksData?.backlinksData?.map((data, index) => {
            const {
              "b.id": id,
              "b.backlink_domain": backlink_domain,
              "b.signup_url": signup_url,
              "b.type": type,
              "b.blacklisted": blacklisted,
              "b.domain_rating": domain_rating,
              "b.robot_group": robot_group,
              "b.ready_status": ready_status,
              "b.follow_status": follow_status,
              "b.traffic": traffic,
              intersect_count,
              city,
              state,
            } = data;
            const source_domains_list_count =
              data["bi.source_domains_list"]?.split(",")?.length;
            return {
              records: data,
              counter: offset + index + 1,
              "b.id": id,
              "b.backlink_domain": backlink_domain,
              "b.signup_url": signup_url,
              "b.type": type || "",
              "b.blacklisted": blacklisted,
              "b.domain_rating": domain_rating,
              "b.robot_group": robot_group,
              "b.ready_status": ready_status || "",
              "b.follow_status": follow_status || "",
              "b.traffic": traffic,
              intersect_count,
              city,
              state,
              source_domains_list_count,
            };
          })}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          totalItems={backlinksData?.totalCount}
          searchText={searchText}
          setSearchText={setSearchText}
          paginationModel={paginationModel}
          onFilterModelChange={onFilterChange}
          CustomToolbar={CustomToolbar}
          height="88vh"
          searchable="No"
          showCount="No"
        />
      </div>

      {modalType === "agreeModal" && (
        <Modal
          open={open}
          handleModal={handleModal}
          cancelButtonRef={cancelButtonRef}
          className="max-w-4xl"
          title="Backlink Confirmations"
        >
          <BacklinkAgreeForm
            handleModal={handleModal}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            agreeData={agreeData}
            setIsConfirmed={setIsConfirmed}
            handleAssignBacklink={handleAssignBacklink}
            handleModalCancel={handleModalCancel}
            selectedData={selectedData}
            selectedDomains={selectedDomains}
            assignBatchToBacklink={assignBatchToBacklink}
            selectedBatch={location?.state?.selectedBatch}
          />
        </Modal>
      )}
    </div>
  );
}
