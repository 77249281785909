import React, { useEffect, useRef } from "react";
import { MdKeyboardArrowRight, MdKeyboardArrowDown } from "react-icons/md";
import { BsFillReplyFill, BsFillPersonFill } from "react-icons/bs";
import { FaReply } from "react-icons/fa";
import dayjs from "dayjs";
import DOMPurify from "dompurify";

const CustomTreeData = ({
  heads,
  items,
  totalRecords,
  onRowClick,
  expandedRow,
  additionalData,
  renderContent,
}) => {
  const containerRef = useRef(null);

  const handleRowClick = (itemId) => {
    if (onRowClick) {
      onRowClick(itemId);
    }
  };

  const addTargetBlank = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const links = doc.querySelectorAll("a");

    links.forEach((link) => {
      if (!link.hasAttribute("target")) {
        link.setAttribute("target", "_blank");
      }
      if (link.href.includes("openstreetmap.org")) {
        link.style.color = "#1f86e0";
      }
      link.setAttribute("data-copy-text", link.href);
    });

    return doc.body.innerHTML;
  };

  const CopyLink = ({ containerRef }) => {
    useEffect(() => {
      const container = containerRef.current;

      const handleMouseOver = (event) => {
        if (event.target.tagName === "A") {
          const link = event.target;

          link.style.position = "relative";

          if (!link.querySelector(".copy-tooltip")) {
            const tooltip = document.createElement("div");
            tooltip.className = "copy-tooltip";

            // Inline styles for the tooltip
            tooltip.style.position = "absolute";
            tooltip.style.top = "80%";
            tooltip.style.left = "50%";
            tooltip.style.transform = "translateX(-50%)";
            tooltip.style.background = "#5fca26";
            tooltip.style.color = "#fff";
            tooltip.style.padding = "8px 16px";
            tooltip.style.borderRadius = "5px";
            tooltip.style.whiteSpace = "nowrap";
            tooltip.style.fontSize = "14px";
            tooltip.style.zIndex = "1000";
            tooltip.style.textAlign = "center";
            tooltip.style.display = "flex";
            tooltip.style.alignItems = "center";
            tooltip.style.justifyContent = "center";
            tooltip.style.marginTop = "5px";

            // Inline styles for the arrow
            const arrow = document.createElement("div");
            arrow.style.position = "absolute";
            arrow.style.bottom = "100%"; // Position above the tooltip
            arrow.style.left = "50%";
            arrow.style.transform = "translateX(-50%)";
            arrow.style.borderWidth = "5px";
            arrow.style.borderStyle = "solid";
            arrow.style.borderColor = `transparent transparent #5fca26 transparent`; // Arrow color
            arrow.style.borderWidth = "5px";

            tooltip.appendChild(arrow);

            const icon = document.createElement("span");
            icon.style.marginRight = "5px";
            icon.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-copy" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/>
            </svg>`;
            icon.style.color = "#fff";

            const text = document.createElement("span");
            text.innerText = "Copy link";

            tooltip.appendChild(icon);
            tooltip.appendChild(text);

            tooltip.addEventListener("click", (e) => {
              e.stopPropagation();
              e.preventDefault();
              const url = link.getAttribute("data-copy-text");
              navigator.clipboard
                .writeText(url)
                .then(() => {
                  tooltip.innerText = "Copied!";
                  setTimeout(() => {
                    if (link.contains(tooltip)) {
                      tooltip.remove();
                    }
                  }, 1000);
                })
                .catch((err) => console.error("Failed to copy link:", err));
            });

            link.appendChild(tooltip);
          }

          link.onmouseleave = () => {
            const tooltip = link.querySelector(".copy-tooltip");
            if (tooltip) {
              tooltip.remove();
            }
          };

          link.onclick = (e) => {
            if (!e.target.classList.contains("copy-tooltip")) {
              link.style.pointerEvents = "none";
              setTimeout(() => {
                link.style.pointerEvents = "auto";
              }, 2000);
            }
          };
        }
      };

      container.addEventListener("mouseover", handleMouseOver);

      return () => {
        container.removeEventListener("mouseover", handleMouseOver);
      };
    }, [containerRef]);

    return null;
  };

  const renderContentChild = (content) => {
    if (!content) {
      return "";
    }

    const isHTML = /<\/?[a-z][\s\S]*>/i.test(content);

    if (isHTML) {
      const sanitizedContent = DOMPurify.sanitize(content);
      const contentWithTargetBlank = addTargetBlank(sanitizedContent);
      return (
        <div ref={containerRef}>
          <CopyLink containerRef={containerRef} />
          <div dangerouslySetInnerHTML={{ __html: contentWithTargetBlank }} />
        </div>
      );
    }
    return <pre>{content}</pre>;
  };

  return (
    <>
      <div className={`rounded w-full bg-white mt-0 p-3 `}>
        <div
          className={`w-full overflow-y-auto scroll-container border border-gray-200 rounded ${
            totalRecords ? "h-[calc(100vh-235px)]" : "h-[calc(100vh-210px)]"
          }`}
        >
          <div className="min-w-full divide-y divide-gray-200 dark:divide-white/25">
            <div className="g-gray-20 bg-[#f8f8f8]  backdrop-blur-md sticky top-0 shadow-sm flex z-10">
              <div className="py-2 px-3 tableHead w-[10%]"></div>
              <div className="py-2 px-3 tableHead w-[150px]">#</div>
              <div className="py-2 px-3 tableHead w-[350px]">FROM</div>
              <div className="py-2 px-3 tableHead w-full">SUBJECT</div>
              <div className="py-2 px-3 tableHead w-[40%]">TIME</div>
            </div>
            <div className="divide-y divide-gray-200 dark:divide-white/40 h-full overflow-y-scroll dark:text-white/70">
              {items && items.length > 0 ? (
                items?.map((item, index) => (
                  <div key={index}>
                    <div
                      className="hover:bg-gray-100 dark:hover:bg-white/10 cursor-pointer flex"
                      onClick={(e) => handleRowClick(item)}
                    >
                      <div className="w-[10%] py-3 px-3 text-gray-500">
                        {expandedRow === item.id ? (
                          <MdKeyboardArrowDown className="w-5 h-5" />
                        ) : (
                          <MdKeyboardArrowRight className="w-5 h-5" />
                        )}
                      </div>
                      <div className={`py-3 px-3 ml-0 w-[150px]`}>
                        {item.serialNumber}
                      </div>{" "}
                      <div className={`py-3 px-3 w-[350px]`}>{item.from}</div>
                      <div className="flex items-center py-3 px-3 w-full overflow-x-auto">
                        <p className="text-normal break-words">
                          <span className="text-black font-semibold">
                            {item?.subject}
                          </span>
                        </p>
                      </div>
                      <div className={`py-3 px-3 w-[40%]`}>{item.time}</div>
                    </div>
                    {expandedRow === item?.id && additionalData && (
                      <div className="border-t border-gray-200 dark:border-white/40">
                        {additionalData ? (
                          <>
                            <div className="p-5 px-2 lg:px-5">
                              <div className="h-ful p-4 bg-neutral-1 overflow-x-auto ">
                                <div
                                  className="font-semibold flex items-center cursor-pointer"
                                  onClick={(e) => handleRowClick(item)}
                                >
                                  <FaReply className="mt- mr-1" /> Back
                                </div>
                                <div className="mt-4">
                                  <div className="h-full p-4 bg-neutral-100">
                                    <div className="flex justify-between items-center">
                                      <h3>
                                        {additionalData &&
                                        additionalData.subject
                                          ? additionalData.subject
                                          : ""}
                                      </h3>
                                      <div className="flex gap-2">
                                        <span>
                                          {" "}
                                          {additionalData && additionalData.time
                                            ? dayjs(additionalData.time).format(
                                                "ddd, MMM D, YYYY h:mm A"
                                              )
                                            : ""}
                                        </span>
                                        <button
                                          className="cursor-pointer"
                                          onClick={(e) => handleRowClick(item)}
                                        >
                                          <BsFillReplyFill />
                                        </button>
                                      </div>
                                    </div>
                                    <div className="flex gap-3 mt-3">
                                      <div className="bg-neutral-500 w-[25px] h-[25px] rounded-full text-white flex justify-center items-center">
                                        <BsFillPersonFill />
                                      </div>
                                      <h5>
                                        {" "}
                                        {additionalData && additionalData.from
                                          ? additionalData.from
                                          : ""}
                                      </h5>
                                    </div>
                                    <div className="p-2 mt-3">
                                      <p className="overflow-y-scroll calc(100vh-100px) ">
                                        {additionalData?.mailcontent
                                          ? renderContentChild(
                                              additionalData?.mailcontent
                                            )
                                          : additionalData?.snippet}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="hover:bg-gray-100 dark:hover:bg-white/10 cursor-pointer flex border-b border-gray-300 text-center">
                            <div className="w-[10%] p-3 text-gray-500"></div>
                            <div className="p-3 w-[80%]">
                              <div className="text-sm">No Records</div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <div className="hover:bg-gray-100 dark:hover:bg-white/10 cursor-pointer flex border-b border-gray-300 text-center">
                  <div className="p-3 w-full">
                    <div className="text-sm">No Records</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomTreeData;
