import React, { useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import Unauthorized from "./Unauthorized";
import { Layout } from "../components";
import { useSelector } from "react-redux";
import { routes } from "./routes";
import { Login } from "../pages";
import userPermission from "../utils/userPermission";
const Router = () => {
  const { user } = useSelector((state) => state.auth);
  const [searchText, setSearchText] = useState("");
  const [show_navlabel, setShowNavbar] = useState(false);
  const toggleNavbar = () => {
    setShowNavbar(!show_navlabel);
  };
  const isAuthenticated = !!user;
  let redirectPath;
  if (user?.user?.role_id === 7) {
    redirectPath = "/backlinks/backlinks-list";
  } else if (userPermission("Dashboard")) {
    redirectPath = "/dashboard";
  } else {
    redirectPath = "/task-list";
  }
  return (
    <BrowserRouter>
      {isAuthenticated ? (
        <Routes>
          {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={
                <Layout
                  user={user}
                  setSearchText={setSearchText}
                  show_navlabel={show_navlabel}
                  toggleNavbar={toggleNavbar}
                  searchText={searchText}
                >
                  <ProtectedRoute
                    pageTitle={route.pageTitle}
                    element={route.element}
                    permission={route.permission}
                  />
                </Layout>
              }
            />
          ))}
          <Route path="*" element={<Navigate to={redirectPath} replace />} />
          <Route
            path="/unauthorized"
            element={
              <Layout>
                <Unauthorized />
              </Layout>
            }
          />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      )}
    </BrowserRouter>
  );
};

export default Router;
