import React, { useEffect, useState,useCallback } from "react";
import ChartSection from "./ChartSection";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../services/api";
import { useSelector } from "react-redux";
import Loading from "../../components/Loader/Loading";
import dayjs from "dayjs";
import MUIDataTable from "../../components/MUIGrid";
import DateRangeComp from "../../components/DateRangePicker";
import { FaSearch } from "react-icons/fa";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import ToggleSwitch from "../../components/common/ToggleSwitch";
import { useNavigate } from "react-router-dom";
import DomainReport from "./DomainReport";
import { ResizableBox } from 'react-resizable'; 
import 'react-resizable/css/styles.css';
import { RiArrowDownSLine } from "react-icons/ri";
const resizeHandleStyle = {
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  height: '5px',
  cursor: 'ns-resize',
  backgroundColor: '#ddd',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};
export default function Dashboard() {
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.gray,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.gray,
      fontSize: 14,
    },
  }));
  const [boxHeight, setBoxHeight] = useState(200);
  const { user } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [toggleStatus, setToggleStatus] = useState(true);
  const currentDate = new Date();
  const initialFilters = {
    start_date:
      new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() - 7,
        0,
        0,
        0,
        0
      )
        .toISOString()
        .slice(0, 23) + "Z",

    end_date: new Date().toISOString().slice(0, 23) + "Z",
  };
  const [filters, setFilters] = React.useState(initialFilters);
  const navigate = useNavigate();
  const [dashboardCount, setDashboardCounts] = useState({});
  const [performaceData, setPerformaceData] = useState({});
  const fetchData = () => {
    setIsLoading(true);
    api
      .get(`${process.env.REACT_APP_PUBLIC_API}/api/users/dashboard_summary`)
      .then((response) => setDashboardCounts(response.data))
      .catch((error) => console.log(error));
    setIsLoading(false);
  };
  const transformData2ToData1Format = (data = []) => {
    const transformedData = {};
    const startDateObj = new Date(filters.start_date);
    startDateObj.setHours(0, 0, 0, 0);

    data.forEach((entry) => {
      const logDate = entry.log_date.split("T")[0];

      if (!transformedData[logDate]) {
        transformedData[logDate] = {};
      }
      transformedData[logDate][entry.name] = { user_id: entry.user_id };

      for (const [status, dateCounts] of Object.entries(
        entry.task_status_log
      )) {
        let statusTotal = 0;

        for (const [statusDate, count] of Object.entries(dateCounts)) {
          const statusDateObj = new Date(statusDate);
          statusDateObj.setHours(0, 0, 0, 0);
          if (statusDateObj <= startDateObj && status !== "inReview") {
            statusTotal += count;
          }
        }

        if (statusTotal > 0) {
          transformedData[logDate][entry.name][status] = statusTotal;
        }
      }
    });

    return transformedData;
  };
  function fillMissingDates(data) {
    const filledData = { ...data };
    function formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    }
    function addDays(date, days) {
      const result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    }

    let currentDate = new Date(filters.start_date);
    const endDateObj = new Date(filters.end_date);
    let prevData = null;

    while (currentDate <= endDateObj) {
      const formattedDate = formatDate(currentDate);
      if (!filledData[formattedDate]) {
        if (prevData) {
          filledData[formattedDate] = JSON.parse(JSON.stringify(prevData));
        }
      } else {
        prevData = filledData[formattedDate];
      }
      currentDate = addDays(currentDate, 1);
    }

    return filledData;
  }
  const fetchPerformanceReport = async () => {
    setIsLoading(true);
    try {
      const res = await api.get(
        `${process.env.REACT_APP_PUBLIC_API}/api/report/user_performance_report`,
        {
          params: {
            start_date: filters?.start_date,
            end_date: filters?.end_date,
            ...(toggleStatus && { is_completed: 1 }),
          },
        }
      );
      if (res.status === 200) {
        // if (!toggleStatus) {
        //   const data = transformData2ToData1Format(res.data);
        //   const missedData = fillMissingDates(data);
        //   setPerformaceData(missedData);
        // } else {
        //   setPerformaceData(res.data);
        // }
        setPerformaceData(res.data);
      }
    } catch (err) {
      console.log("👊 ~ fetchPerformanceReport ~ err:", err);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, [user?.user?.id, user?.user?.role?.level]);
  useEffect(() => {
    if (filters.start_date && filters.end_date) {
      fetchPerformanceReport();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.start_date, filters.end_date, toggleStatus]);
  const columnDefs = [
    {
      headerName: "#",
      field: "counter",
      width: 60,
      filterable: false,
      sortable: false,
    },
    {
      field: "name",
      headerName: "Name",
      minWidth: 180,
      sortable: false,
    },
  ];
  function addSpaceToCamelCase(str) {
    return str.replace(/([a-z])([A-Z])/g, "$1 $2");
  }
  function generateDateColumns(startDate, endDate) {
    const start = dayjs(startDate);
    const end = dayjs(endDate);
    const diffDays = end.diff(start, "day");
    const columns = [];
    for (let i = 0; i <= diffDays; i++) {
      const currentDate = start.add(i, "day");
      const formattedDate = currentDate.format("YYYY-MM-DD"); // date format
      columns.push({
        field: `${formattedDate}`,
        headerName: formattedDate,
        minWidth: 100,
        flex: 1,
        valueGetter: (params) => {
          const dayData = params.row[formattedDate];
          if (dayData && typeof dayData === "object") {
            const sum = Object.entries(dayData)
              .filter(([key]) => key !== "user_id")
              .reduce((sum, [, value]) => sum + value, 0);

            const details = Object.entries(dayData)
              .map(([key, value]) => `${key}: ${value}`)
              .join(", ");

            return { sum, details };
          }
          return { sum: 0, details: "" };
        },
        renderCell: (params) => {
          const { sum } = params.value;
          const dayData = params.row[formattedDate] || {};
          const userId = dayData?.user_id;
          const hasData = Object.keys(dayData || {}).length > 0;
          return (
            <>
              <BootstrapTooltip
                title={
                  hasData ? (
                    <div className="flex flex-col gap-2 p-1">
                      {Object.entries(dayData)
                        .filter(([key]) => key !== "user_id")
                        .map(([key, value], index) => (
                          <button
                            key={index}
                            className="text-white py-1 px-2 rounded hover:bg-blue-600 capitalize btnPrimary"
                            onClick={() =>
                              handleHoverButton(key, userId, formattedDate)
                            }
                          >
                            {`${addSpaceToCamelCase(key)}: ${value}`}
                          </button>
                        ))}
                    </div>
                  ) : null
                }
                placement="top"
              >
                <div className="text-center bg-[#93c5fd] py-1 px-2 rounded hover:cursor-pointer">
                  {sum}
                </div>
              </BootstrapTooltip>
            </>
          );
        },
      });
    }
    return columns;
  }
  const handleHoverButton = (filter, userId, formattedDate) => {
    navigate(`/settings/link-builders/${userId}`, {
      state: {
        filter,
        selectedDate: formattedDate,
      },
    });
  };
  const dateColumns = generateDateColumns(filters.start_date, filters.end_date);
  function transformData(data) {
    const users = new Set();
    const dates = Object.keys(data);
    dates.forEach((date) => {
      Object.keys(data[date]).forEach((user) => {
        users.add(user);
      });
    });
    const transformedData = Array.from(users).map((user, index) => {
      const userData = { counter: index + 1, name: user };
      dates.forEach((date) => {
        userData[date] = data[date][user] || null;
      });
      return userData;
    });

    return transformedData;
  }

  const transformedData = transformData(performaceData || {});

  const handleToggleChange = (newStatus) => {
    setToggleStatus(newStatus);
  };
  const handleResize = useCallback((e, { size }) => {
    setBoxHeight(size.height); // Update the state with the new height
  }, []);
  return (
    <div className="p-6 px-2 md:px-6">
      {isLoading && <Loading />}
      <ToastContainer />
      <div className="flex lg:flex-row flex-col lg:items-center justify-between px-5 md:px-0">
        <div className="lg:hidden">
          <img src="/img/robo_logo_3.png" className="h-12 dark:hidden" alt="" />
          <img src="/img/logo.svg" className="h-12 hidden dark:block" alt="" />
          <h3 className="text-2xl font-bold my-2">Dashboard</h3>
        </div>
        <div>
          <h1 className="text-2xl lg:text-3xl font-bold capitalize">
            Hi, {user?.user?.name}{" "}
            <span className="text-lg text-gray-600">
              ( {user?.user?.role?.name} )
            </span>{" "}
            ✋
          </h1>
        </div>
      </div>
      <ChartSection user={user?.user} dashboardCount={dashboardCount} />

      {user && user?.user?.role_id === 1 ? (
        <ResizableBox
        className="relative bg-white my-3 "
        width="100%" // Set width to full container width
        height={boxHeight}
        minConstraints={[800, 200]} // Minimum width and height
        maxConstraints={[Infinity, 1200]} // Max width is infinite, height constrained
        axis="y" // Allow only vertical resizing
        handle={<div style={resizeHandleStyle}><RiArrowDownSLine  size={22}/></div>} 
        //resizeHandles={['s']}
        onResize={handleResize}
      >
        <div className="bg-white my-3 border rounded">
          <div className="flex justify-between">
            <div className="p-3 flex items-center space-x-4 text-xl font-semibold capitalize">
              <span>User Performance Report</span>
              <ToggleSwitch onToggleChange={handleToggleChange} />
            </div>
            <div className="p-3 flex flex-row">
              <DateRangeComp
                inputClassName="!font-medium w-[220px] text-center bg-[#e9ecef] text-sm rounded-l !py-1 !px-2 border border-[#ced4da] !mb-0 rounded-0"
                pickerClassName="!left-1/2 !top-8 -translate-x-[75%]"
                dateRange
                onChange={(e) => {
                  const endDate = new Date(e.selection.endDate);
                  endDate.setHours(23, 59, 59, 59);
                  const formattedEndDate =
                    endDate.toISOString().slice(0, 23) + "Z";

                  const startDate = new Date(e.selection.startDate);
                  startDate.setHours(0, 0, 0, 0);
                  const formattedStartDate =
                    startDate.toISOString().slice(0, 23) + "Z";
                  setFilters({
                    ...filters,
                    end_date: formattedEndDate,
                    start_date: formattedStartDate,
                  });
                }}
                startDate={filters.start_date}
                endDate={filters.end_date}
              />
              <button
                variant="btn_cancel"
                className="max-h-[30px] !bg-[#042A42] ml- rounded-r px-3 py-1.5 text-white"
                onClick={() => fetchPerformanceReport()}
              >
                <FaSearch size={14} />
              </button>
            </div>
          </div>
          <MUIDataTable
            columnDefs={[...columnDefs, ...dateColumns.reverse()]}
            items={transformedData}
            pagination="No"
            searchable="No"
            showCount="No"
            toolbar="No"
            isLoading={isLoading}
            height={boxHeight-80}
            hideFooter
          />
        </div>
        </ResizableBox>
      ) : null}
      {user && user?.user?.role_id === 1 ? <DomainReport /> : null}
    </div>
  );
}
