import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BacklinksTable from "../../components/BacklinksTable";

export default function Backlinks() {
  return (
    <div className="p-5">
      <ToastContainer />
      <BacklinksTable />
    </div>
  );
}
