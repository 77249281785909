import React from "react";

export default function Badge({ value, hover }) {
  return (
    <span
      className={`text-white dark:text-secondary h-4 flex items-center justify-center ml-2 py-[5px] px-[5px] text-xs text-center rounded-full dark:bg-white bg-secondary hover:bg-green-200 hover:text-gray-600 transition-colors duration-300`}
    >
      {value || 0}
    </span>
  );
}
