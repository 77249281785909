import React from "react";
import { Modal } from "../../components";
import { toast } from "react-toastify";
import SubRole from "./SubRole";
import api from "../../services/api";
function NewSelectTaskForm({ onCancelForm, getRoles, roles }) {
  const [selectedData, setSelectedData] = React.useState([]);
  const [permissions, setPermissions] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [role_id, setRole_id] = React.useState();
  const cancelButtonRef = React.useRef(null);
  const getPermissions = () => {
    setIsLoading(true);
    api
      .get(
        `${process.env.REACT_APP_PUBLIC_API}/api/permissions/list_permissions`
      )
      .then((response) => {
        const parsedPermissions = response.data
          ?.filter((item) => item.name?.trim() && item.sub_permissions?.trim())
          .map((item) => ({
            ...item,
            sub_permissions: item.sub_permissions
              ? JSON.parse(item.sub_permissions)
              : [],
          }));
        setPermissions(parsedPermissions);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  React.useEffect(() => {
    getPermissions();
  }, []);
  const assignTask = async (e) => {
    e.preventDefault();
    if (role_id) {
      setIsLoading(true);
      try {
        const res = await api.post(`/api/permissions/add_role_permissions`, {
          role_id,
          permissions: selectedData,
        });
        if (res?.status === 200) {
          toast.success("Permission updated");
          getRoles();
          onCancelForm();
        } else {
          toast.error("Permission couldn't be updated");
        }
        setIsLoading(false);
      } catch (error) {
        console.error(
          "🚀  file: NewSelectTaskForm.jsx:101  assignTask ~ error:",
          error
        );
        setIsLoading(false);
        toast.error("Permission couldn't be updated");
      }
    } else {
      toast.error("Please Select a role");
    }
  };
  const tasks = permissions?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        // searchInput &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
  const onSingleselect = (item) => {
    if (!role_id) return toast.error("Please select the role");
    setSelectedData(handleCheckboxSelection(item));
  };

  function handleCheckboxSelection(name) {
    const findMenuItem = (menu, currentLabel) => {
      for (const item of menu) {
        if (item.name === currentLabel) {
          return item;
        } else if (item.sub_permissions) {
          const foundInChildren = findMenuItem(
            item.sub_permissions,
            currentLabel
          );
          if (foundInChildren) {
            return foundInChildren;
          }
        }
      }
      return null;
    };

    const menuItem = findMenuItem(permissions, name);

    if (!menuItem) {
      console.error("Menu item not found");
      return selectedData;
    }

    const getParentLabel = (menu, currentLabel) => {
      const parentItem = menu.find((item) => {
        if (
          item.sub_permissions &&
          item.sub_permissions.some((child) => child.name === currentLabel)
        ) {
          return true;
        }
        return false;
      });
      return parentItem ? parentItem.name : null;
    };

    const getDescendantLabels = (menu, currentLabel) => {
      const foundItem = findMenuItem(menu, currentLabel);
      if (!foundItem) {
        return [];
      }

      const descendantLabels = [currentLabel];
      if (foundItem.sub_permissions) {
        for (const child of foundItem.sub_permissions) {
          descendantLabels.push(
            ...getDescendantLabels(foundItem.sub_permissions, child.name)
          );
        }
      }
      return descendantLabels;
    };

    const parentLabel = getParentLabel(permissions, name);
    const descendantLabels = getDescendantLabels(permissions, name);

    if (menuItem.sub_permissions) {
      if (selectedData.includes(name)) {
        // Deselecting checkbox for a parent item
        const remainingSelectedLabels = selectedData.filter(
          (selectedLabel) => !descendantLabels.includes(selectedLabel)
        );

        // If all children and sub-children are deselected, remove the parent name too
        if (
          remainingSelectedLabels.includes(name) &&
          parentLabel &&
          !remainingSelectedLabels.some(
            (selectedLabel) =>
              getParentLabel(permissions, selectedLabel) === parentLabel
          )
        ) {
          remainingSelectedLabels.push(parentLabel);
        }

        return remainingSelectedLabels.filter(
          (selectedLabel) => selectedLabel !== name
        );
      } else {
        // Selecting checkbox for a parent item
        const updatedSelectedLabels = Array.from(
          new Set([...selectedData, name, ...descendantLabels])
        );

        // If any child or sub-child is selected, select the parent name
        if (parentLabel && !updatedSelectedLabels.includes(parentLabel)) {
          updatedSelectedLabels.push(parentLabel);
        }

        return updatedSelectedLabels;
      }
    } else {
      // Simple name without sub_permissions
      if (selectedData.includes(name)) {
        const remainingLabels = selectedData.filter(
          (selectedLabel) => !descendantLabels.includes(selectedLabel)
        );

        // If a child or sub-child name is deselected and no names of its parent remain, remove the parent name
        if (
          parentLabel &&
          !remainingLabels.some(
            (selectedLabel) =>
              getParentLabel(permissions, selectedLabel) === parentLabel
          )
        ) {
          return remainingLabels.filter(
            (selectedLabel) => selectedLabel !== parentLabel
          );
        }

        return remainingLabels;
      } else {
        // If a child or sub-child name is selected, select its parent name
        const updatedSelectedLabels = [...selectedData, name];

        // Add descendant names only if the name has sub_permissions or sub-children
        if (descendantLabels.length > 1) {
          updatedSelectedLabels.push(...descendantLabels.slice(1));
        }

        // Add parent name if it exists and is not already in the list
        if (parentLabel && !updatedSelectedLabels.includes(parentLabel)) {
          updatedSelectedLabels.push(parentLabel);
        }

        return updatedSelectedLabels;
      }
    }
  }
  function handleSelectAll() {
    const allLabels = getAllLabels();
    // Check if all names are already selected
    const allLabelsSelected = allLabels.every((name) =>
      selectedData.includes(name)
    );
    if (allLabelsSelected) {
      // If all names are selected, remove all names
      return selectedData.filter((name) => !allLabels.includes(name));
    } else {
      // If not all names are selected, select all names
      return Array.from(new Set([...selectedData, ...allLabels]));
    }
  }

  function getAllLabels() {
    const names = [];
    const collectLabels = (menu) => {
      for (const item of menu) {
        names.push(item.name);

        if (item.sub_permissions) {
          collectLabels(item.sub_permissions);
        }
      }
    };

    collectLabels(permissions);
    return names;
  }
  function handleSelectAllCheckboxChange() {
    if (!role_id) return toast.error("Please select the role");
    const updatedSelectedData = handleSelectAll();
    // Update your state or perform any other necessary actions with updatedSelectedData
    setSelectedData(updatedSelectedData);
  }
  const isSelectAll = () => {
    const allLabels = getAllLabels();
    // Check if all names are already selected
    const allLabelsSelected = allLabels.every((name) =>
      selectedData.includes(name)
    );
    if (allLabelsSelected) {
      // If all names are selected, remove all names
      return true;
    } else {
      // If not all names are selected, select all names
      return false;
    }
  };
  const handleRoleChange = (role_id) => {
    const selectedRole = roles?.find((item) => +role_id === item?.id);
    if (selectedRole) {
      setRole_id(role_id);
      setSelectedData(selectedRole?.role_permissions || []);
    }
  };
  return (
    <>
      <Modal
        isUpdate={false}
        onSubmit={assignTask}
        open={false}
        handleModal={onCancelForm}
        cancelButtonRef={cancelButtonRef}
        handleModalSubmit={assignTask}
        className="max-w-xl"
        title={"Manage Permissions"}
        modalType="rolePermissions"
        isLoading={isLoading}
      >
        <div className="flex flex-row gap-x-2">
          <select
            value={role_id}
            onChange={(e) => handleRoleChange(e.target.value)}
            name="roleId"
            // error={errors.level}
          >
            <option value="">Select role</option>
            {roles?.map((item) => (
              <option key={item?.id} value={item?.id}>
                {item?.name}
              </option>
            ))}
          </select>
          <div className="relative w-full col-span-6 md:col-span-6 flex justify-end items-end">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                // aria-hidden="true"
                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <input
              type="text"
              className="h-[38px] bg-white border border-gray-300 text-gray-900 text-sm rounded-[0.2rem] w-full pl-12 p-2.5 "
              placeholder="Search"
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="mt-2 h-[460px] overflow-y-auto">
          <div className="flex items-center gap-2 border-b py-2">
            <input
              className="h-5 w-5 focus:ring-0 cursor-pointer form-checkbox text-primary-100 rounded-sm"
              type={"checkbox"}
              checked={isSelectAll()}
              onClick={handleSelectAllCheckboxChange}
            />
            Select All
          </div>
          {tasks.map((item, key) => {
            const { name, sub_permissions } = item;
            return (
              <div key={key}>
                {sub_permissions?.length ? (
                  <SubRole
                    children={sub_permissions}
                    label={name}
                    onSingleselect={onSingleselect}
                    selectedData={selectedData}
                  />
                ) : (
                  <div
                    className="flex items-center gap-2 border-b py-2"
                    title={name}
                  >
                    <input
                      className="h-5 w-5 focus:ring-0 cursor-pointer form-checkbox text-primary-100 rounded-sm"
                      type={"checkbox"}
                      onChange={() => onSingleselect(name)}
                      checked={selectedData?.includes(name)}
                    />
                    <span>{name}</span>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </Modal>
    </>
  );
}

export default NewSelectTaskForm;
