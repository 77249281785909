import { useState } from "react";
import { EyeIcon, EyeSlashIcon, EnvelopeIcon } from "@heroicons/react/24/solid";
import { LuFilter } from "react-icons/lu";
const InputField = ({
  name,
  label,
  onChange,
  onBlur,
  type,
  disabled = false,
  placeholder,
  value,
  required,
  max,
  min,
  error,
  className,
  accept,
  labelClass,
  autoComplete,
  showFilterIcon = false
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className={`w-full ${className}`}>
      <label className={`inputLabel ${labelClass}`} htmlFor={name}>
        {label}
      </label>
      <div className="relative">
        <input
          type={passwordVisible ? "text" : type || "text"}
          disabled={disabled}
          name={name}
          max={max}
          min={min}
          placeholder={placeholder}
          className={`inputField mt-1 dark:bg-dark dark:border-white/20 text-black ${
            error ? "border-red-500" : ""
          } custom-date-input`}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          required={required}
          accept={accept}
          autoComplete={autoComplete}
        />
        {type === "password" && (
          <button
            type="button"
            className="absolute inset-y-0 right-0 px-3 py-2 bg-[#5eca2a] mt-1 rounded-r"
            onClick={togglePasswordVisibility}
          >
            {passwordVisible ? (
              <EyeSlashIcon className="h-5 w-5 text-gray500 text-white" />
            ) : (
              <EyeIcon className="h-5 w-5 text-gray500 text-white" />
            )}
          </button>
        )}
        {type === "username" && (
          <button className="absolute inset-y-0 right-0 px-3 py-2 bg-[#5eca2a] mt-1 rounded-r" disabled>
            <EnvelopeIcon className="h-5 w-5 text-gray500 text-white" />
          </button>
        )}
        {showFilterIcon && type !== "date" && (
          <button className="absolute inset-y-0 right-0 px-3 py-0 bg-gray-100 mt-1 rounded-r dark:border-white/20">
            <LuFilter className="h-5 w-5 text-black" />
          </button>
        )}
      </div>
      {error && <span className="text-red-500">{error}</span>}
    </div>
  );
};

export default InputField;
