import { PhoneIcon } from "@heroicons/react/24/solid";
import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import { Container, FullContainer } from "../../components";
import axios from "axios";

export default function Header() {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`fixed w-full top-0 py-3 lg:flex items-center justify-center z-50 transition-colors duration-300 ${
        isScrolled
          ? "bg-white text-black shadow-md"
          : "bg-transparent text-white"
      }`}
    >
      <FullContainer>
        <Container>
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center">
              <img
                src={isScrolled ? "/img/robo_logo_3.png" : "/img/robo-logo.png"}
                className="h-9 md:h-10 w-auto mr-8"
                alt=""
              />
              <div className="hidden md:flex items-center">
                <Link
                  spy={true}
                  smooth={true}
                  to="LoginForm"
                  className="px-3 py-2 transition-all border-b-2 border-transparent hover:border-secondary"
                >
                  Home
                </Link>
                <Link
                  spy={true}
                  smooth={true}
                  to="AboutUs"
                  className="px-3 py-2 transition-all border-b-2 border-transparent hover:border-secondary"
                >
                  About
                </Link>
                <Link
                  spy={true}
                  smooth={true}
                  to="pricing"
                  className="px-3 py-2 transition-all border-b-2 border-transparent hover:border-secondary"
                >
                  Pricing
                </Link>
                <Link
                  spy={true}
                  smooth={true}
                  to="FAQs"
                  className="px-3 py-2 transition-all border-b-2 border-transparent hover:border-secondary"
                >
                  FAQs
                </Link>
                <a
                  href="tel:+1 (800) 542-2323"
                  className="flex items-center px-3 py-2 transition-all border-b-2 border-transparent hover:border-secondary gap-1"
                >
                  <PhoneIcon className="h-4" />
                  +1 (800) 542-2323
                </a>
              </div>
            </div>
            {isScrolled && (
              <div className="flex items-center justify-end gap-2">
                <Link
                  spy={true}
                  smooth={true}
                  to="LoginForm"
                  className="btnPrimary border-2 h-[37px] hover:bg-secondary transition-all hover:text-white border-secondary text-black bg-transparent"
                >
                  Login
                </Link>
                <Link
                  spy={true}
                  smooth={true}
                  to="LoginForm"
                  className="btnPrimary shadow-none rounded-md"
                >
                  Create Account
                </Link>
              </div>
            )}
          </div>
        </Container>
      </FullContainer>
    </div>
  );
}
