import React, { useEffect, useRef, useState } from "react";
import { PencilSquareIcon } from "@heroicons/react/24/solid";
import { Modal } from "../../components";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../services/api";
import userPermission from "../../utils/userPermission";
import Loading from "../../components/Loader/Loading";
import MUIDataTable from "../../components/MUIGrid";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import AddUpdateMerchant from "./AddUpdateMerchant";
import CustomBreadcrumbs from "../../components/common/CustomBreadcrumbs";
export default function Merchants() {
  const [isMerchants, setMerchants] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalType, setModalType] = useState("");
  const [selectedMerchant, setSelectedMerchant] = useState({});

  const fetchMerchantsData = async () => {
    setIsLoading(true);
    try {
      const response = await api.get(
        `${process.env.REACT_APP_PUBLIC_API}/api/merchants`
      );
      setMerchants(response.data);
    } catch (error) {
      console.error(`Error fetching users data: ${error}`);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fetchMerchantsData();
  }, []);

  // Modal
  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);
  const handleModal = () => {
    setOpen(!open);
  };

  const handleNewMerchant = () => {
    handleModal(true);
    setModalType("newMerchant");
  };

  const handleEditMerchant = (merchant) => {
    setSelectedMerchant(merchant);
    setModalType("editMerchant");
    handleModal();
  };
  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer className="mb-3 mt-3 flex items-center justify-between">
        <div className="flex">
          <GridToolbarColumnsButton
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
          />
          <GridToolbarDensitySelector
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
          />
          <GridToolbarFilterButton
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
            ref={setFilterButtonEl}
          />
        </div>
        <div className="flex">
          {userPermission("Create Merchant") ? (
            <button className="btnPrimary mr-2" onClick={handleNewMerchant}>
              Create Merchant
            </button>
          ) : null}
        </div>
      </GridToolbarContainer>
    );
  }
  const columnDefs = [
    { headerName: "Sr#", field: "counter", width: 80, filterable: false },
    {
      headerName: "Name",
      field: "name",
      flex: 1,
      minWidth: 180,
    },
    {
      headerName: "Company Name",
      field: "company_name",
      flex: 1,
      minWidth: 180,
    },
    { headerName: "Email", field: "email", flex: 1, minWidth: 180 },
    {
      headerName: "Address",
      field: "address",
      flex: 1,
      minWidth: 180,
    },
    { headerName: "Phone", field: "phone", width: 100 },
    {
      headerName: "Actions",
      field: "role",
      minWidth: 120,
      filterable: false,
      renderCell: (params) =>
        userPermission("Edit Merchant") ? (
          <div className="relative">
            <div className="flex items-center justify-center">
              <PencilSquareIcon
                key={`${params?.row.id}-action`}
                className="h-4 cursor-pointer text-black-400"
                onClick={() => handleEditMerchant(params.row.records)}
                title="Edit Merchant"
              />
            </div>
          </div>
        ) : null,
    },
  ];
  return (
    <div className="px-6 pt-4">
      <CustomBreadcrumbs
        crumbs={[
          { label: "Home", link: "/" },
          { label: "Merchants", link: "" },
        ]}
      />
      <ToastContainer />
      {isLoading && <Loading />}
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={isMerchants?.map((data, index) => {
            const { name, company_name, email, address, phone } = data;
            return {
              records: data,
              counter: index + 1,
              name,
              company_name,
              email,
              address,
              phone,
            };
          })}
          CustomToolbar={CustomToolbar}
          height="88vh"
          searchable="No"
          showCount="No"
          pagination="No"
          paginationMode="client"
        />
      </div>
      <Modal
        open={open}
        handleModal={handleModal}
        cancelButtonRef={cancelButtonRef}
        className="max-w-5xl"
        title={
          (modalType === "newMerchant" && "Create Merchant") ||
          (modalType === "editMerchant" && "Edit Merchant")
        }
      >
        <AddUpdateMerchant
          handleModal={handleModal}
          fetchMerchantsData={fetchMerchantsData}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          modalType={modalType}
          merchant={selectedMerchant}
        />
      </Modal>
    </div>
  );
}
