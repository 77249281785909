import React from "react";
import { FcPrevious, FcNext } from "react-icons/fc";
import { toast } from "react-toastify";
const CustomPagination = ({
  currentPage,
  totalPages,
  onPageChange,
  is_browser_active,
}) => {
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === totalPages;

  const handlePrevious = () => {
    if(is_browser_active){
     toast.error("Please stop the previous task first!");
     return;
    }
  
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    if(is_browser_active){
      toast.error("Please stop the previous task first!");
      return;
     }
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <div className="flex justify-between items-center mt-0">
      <button
        onClick={handlePrevious}
        disabled={isFirstPage}
        className={`text-sm hover:bg-blue-700 py-2 px-4 rounded btnTable text-black ${
          isFirstPage
            ? "cursor-not-allowed bg-gray-200"
            : "hover:bg-gray-100 bg-white"
        }`}
      >
        <FcPrevious size={18} /> Prev
      </button>
      <div className="text-gray-800 mx-3 my-1 font-semibold">
        {currentPage} of {totalPages}
      </div>
      <button
        onClick={handleNext}
        disabled={isLastPage}
        className={`text-sm hover:bg-blue-700 py-2 px-4 rounded btnTable text-black ${
          isLastPage
            ? "cursor-not-allowed bg-gray-200"
            : "hover:bg-gray-100 bg-white"
        }`}
      >
        Next <FcNext size={18} />
      </button>
    </div>
  );
};

export default CustomPagination;
