// rootReducer.js
import { combineReducers } from "@reduxjs/toolkit";
import selectedGroupReducer from "./slices/selectedGroupSlice";
import selectedUserReducer from "./slices/selectedUserSlice";
import selectedBatchReducer from "./slices/selectedBatchSlice";
import selectedBatchesReducer from "./slices/selectedBatchesSlice";
import authSlice from "./slices/authSlice";
import merchantSlice from "./slices/merchantSlice";
import mailboxRealTimeSlice from "./slices/mailboxRealTimeSlice";
import taskPrevDomainSlice  from "./slices/taskPrevDomainSlice";

const rootReducer = combineReducers({
  selectedGroup: selectedGroupReducer,
  selectedBatch: selectedBatchReducer,
  selectedBatches: selectedBatchesReducer,
  selectedUser: selectedUserReducer,
  auth: authSlice,
  merchant: merchantSlice,
  realtimeMails: mailboxRealTimeSlice,
  taskDomainData:taskPrevDomainSlice,
});

export default rootReducer;
