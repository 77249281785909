import React, { useState, useRef } from "react";
import api from "../../services/api";
// import InputField from "../../common/InputField";
import { toast } from "react-toastify";
import { InputField, Modal } from "../../components";
import TextArea from "../../components/common/TextArea";
function AddTabelRows({ setOpen, isImport, industries, fetchTemplates,editingRecord,selectedIndustry }) {
  console.log("🚀 ~ file: AddUpdateUserForm.jsx:9 ~ AddTabelRows ~ editingRecord:", editingRecord)
  let initialData = {
    type: "",
    industry_id: "",
    v_tag: "",
    tag_name: "",
    text: "",
  };
  if (editingRecord) {
    const { id, type, industry_id,v_tag,tag_name,text } = editingRecord;
    initialData = {
      id,
      type,
      industry_id,
      v_tag,
      tag_name,
      text
    };
  }
  
  const [payloadData, setPayloadData] = useState(initialData);
  console.log(
    "🚀 ~ file: ImportModal.jsx:13 ~ ImportModal ~ payloadData:",
    payloadData
  );
  let resetData = {
    type: "",
    industry_id: payloadData?.industry_id,
    v_tag: "",
    tag_name: "",
    text: "",
  };
  // const [open, setOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
 // const [excelFile, setExcelFile] = useState();
  //console.log("🚀 ~ file: ImportModal.jsx:23 ~ ImportModal ~ file:", excelFile);
  const handleModal = () => {
    setOpen(!isImport);
  };
  const cancelButtonRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors({})
    const errors = {};
    if (!payloadData?.industry_id) {
      errors.industry_id = "Please select the industry.";
    }
    if (!payloadData?.type?.trim()) {
      errors.type = "Type is required";
    }
    if (!payloadData?.v_tag?.trim()) {
      errors.v_tag = "V tag is required";
    }

    if (!payloadData?.tag_name?.trim()) {
      errors.tag_name = "Tag Name is required";
    }
    if (!payloadData?.text?.trim()) {
      errors.text = "Text is required";
    }
    //   if(!excelFile){
    //     return setShowWarning(true)
    // }
    
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    if(editingRecord){
      api
      .put(
        `${process.env.REACT_APP_PUBLIC_API}/api/backlinks/editTableRows`,[payloadData],
      )
      .then((response) => {
        
        toast.success("Table Row update successfully!", { autoClose: 3000 });
        fetchTemplates({page:1,perPage:100,industry_id: selectedIndustry,});
        handleModal();
      })
      .catch((error) => {
        console.log(
          "🚀 ~ file: ImportModal.jsx:60 ~ handleSubmit ~ error:",
          error
        );
        toast.error(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : "Couldn't be updated",
          { autoClose: 3000 }
        );
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
    }else{
      api
      .post(
        `${process.env.REACT_APP_PUBLIC_API}/api/backlinks/addTableRows`,[payloadData],
      )
      .then((response) => {
        // handleModal();
        toast.success("Table Row added successfully!", { autoClose: 3000 });
        fetchTemplates({page:1,perPage:100,industry_id: selectedIndustry,});
        setErrors({})
        setPayloadData(resetData)
      })
      .catch((error) => {
        console.log(
          "🚀 ~ file: ImportModal.jsx:60 ~ handleSubmit ~ error:",
          error
        );
        toast.error(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : "Couldn't be added",
          { autoClose: 3000 }
        );
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
    }
    
  };
  return (
    <div>
      <Modal
        open={false}
        handleModal={handleModal}
        cancelButtonRef={cancelButtonRef}
        handleModalSubmit={handleSubmit}
        className="max-w-[50%]"
        title={"Add Rows"}
        modalType="backlinks_import"
        isLoading={isLoading}
      >
        <div className="grid md:grid-cols-2 gap-4">
          <div>
            <label htmlFor="roleId" className="inputLabel">
              Industry:
            </label>
            <select
              value={payloadData.industry_id}
              onChange={(e) => {
                  setPayloadData({ ...payloadData, industry_id: e.target.value });
              }}
              name="industry_id"
              className={`inputField mt-1 ${
                errors.industry_id ? "border-red-500" : ""
              }`}
              error={errors.industry_id}
            >
              <option value="">Select Industry</option>
              <option value={0}>Common</option>
              {industries.map(({ id, industry_name }, index) => (
                <option key={index} value={id}>
                  {industry_name}
                </option>
              ))}
            </select>
            {errors.industry_id && <div className="text-red-500">{errors?.industry_id}</div>}
          </div>
          <InputField
          label="type"
          name="type"
          placeholder="Enter type"
          type="text"
          value={payloadData?.type}
          onChange={(e) => setPayloadData({...payloadData,type: e.target.value})}
          error={errors.type} // Pass the error prop for validation
        />
        <InputField
          label="V Tag"
          name="v_tag"
          placeholder="Enter V tag"
          type="text"
          value={payloadData?.v_tag}
          onChange={(e) => setPayloadData({...payloadData,v_tag: e.target.value})}
          error={errors.v_tag} // Pass the error prop for validation
        />
        <InputField
          label="Tag Name"
          name="v_tag"
          placeholder="Enter Tag Name"
          type="text"
          value={payloadData?.tag_name}
          onChange={(e) => setPayloadData({...payloadData,tag_name: e.target.value})}
          error={errors.tag_name} // Pass the error prop for validation
        />
        <TextArea
          label="Text"
          name="text"
          placeholder="Enter Text"
          type="text"
          value={payloadData?.text}
          onChange={(e) => setPayloadData({...payloadData,text: e.target.value})}
          error={errors.text} // Pass the error prop for validation
          rows={3}
          className="col-span-2"
        />
        </div>
      </Modal>
    </div>
  );
}

export default AddTabelRows;
