import React, { useEffect, useState } from "react";
import { DataTable, Status } from "../../components";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../services/api";
import { toast } from "react-toastify";

export default function GroupDomainList() {
  const { user, group, batch } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [groupListBatch, setGroupListBatch] = useState([]);
  const [domainList, setDomainList] = useState([]);
  const [myUser, setMyUser] = useState({});
  const [myGroup, setMyGroup] = useState({});
  const [myBatch, setMyBatch] = useState({});
  const [users, setUsers] = useState([]);

  // Fetch users list
  useEffect(() => {
    setIsLoading(true);
    api
      .get(`${process.env.REACT_APP_PUBLIC_API}/api/users`)
      .then((res) => {
        setUsers(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const fetchGroupListHasBatch = () => {
    api
      .post(
        `${process.env.REACT_APP_PUBLIC_API}/api/groupBatches/groupNameListHasBatch/`,
        {
          user_id: myUser.id,
          level: myUser.role_id.level,
        }
      )
      .then((response) => {
        setGroupListBatch(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const findMyUser = () => {
    const foundUser = users?.users?.find((item) => item.name === user);
    if (foundUser) {
      setMyUser(foundUser);
    }
  };

  const findMyGroup = () => {
    const foundGroup = groupListBatch?.groupList?.find(
      (item) => item.group_name === group
    );
    if (foundGroup) {
      setMyGroup(foundGroup);
    }
  };

  const findMyBatch = () => {
    const foundBatch = groupLinkBuilderBatchList?.groupListbatch?.find(
      (item) => item.name === batch
    );
    if (foundBatch) {
      setMyBatch(foundBatch);
    }
  };

  const [groupLinkBuilderBatchList, setGroupLinkbuilderBatchList] = useState(
    {}
  );
  const getGroupLinkbuilderBatchList = async () => {
    api
      .post(
        `${process.env.REACT_APP_PUBLIC_API}/api/groupBatches/groupLinkbuilderBatchList`,
        {
          user_id: myUser.id,
          group_id: myGroup.id,
        }
      )
      .then((response) => {
        setGroupLinkbuilderBatchList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getGroupDomainList = () => {
    api
      .get(
        `${process.env.REACT_APP_PUBLIC_API}/api/groupBatches/batchDomainList/${myBatch.id}`
      )
      .then((res) => {
        if (res.data.length > 0) {
          setDomainList(res.data);
        } else {
          toast.error("no domains assigned");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setIsLoading(false);
  };

  // Find user from users list to get user id.
  useEffect(() => {
    if (users?.totalCount > 0) {
      findMyUser();
    }
   // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [users]);

  // get groups list if you have user
  useEffect(() => {
    if (myUser?.name) {
      fetchGroupListHasBatch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myUser]);

  // Find group from group list
  useEffect(() => {
    if (groupListBatch?.totalCount > 0) {
      findMyGroup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupListBatch]);

  // Get group batches list if you have group
  useEffect(() => {
    if (myGroup?.group_name) {
      getGroupLinkbuilderBatchList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myGroup]);

  // Find batch from batches list
  useEffect(() => {
    if (groupLinkBuilderBatchList?.totalCount > 0) {
      findMyBatch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupLinkBuilderBatchList]);

  // Get domains list if you have batch
  useEffect(() => {
    if (myBatch?.name) {
      getGroupDomainList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myBatch]);

  const navigate = useNavigate();
  const getBacklinkList = (domain) => {
    navigate(`/users/${user}/${group}/${batch}/${domain.domain}`);
  };

  return (
    <div className="p-7">
      <DataTable
        searchBox
        title={`Domain List Of ${batch}`}
        isLoading={isLoading}
        heads={[
          "sr#",
          "domain",
          "action",
          <p className="text-center">Assigned</p>,
          <p className="text-center">Not Assigned</p>,
          <p className="text-center">Total</p>,
          <p className="pl-8">Status</p>,
        ]}
        items={domainList?.map((domain, index) => ({
          serialNumber: index + 1,
          domain: domain["domain"],
          action: (
            <div
              className="btnPrimary w-fit"
              onClick={() => getBacklinkList(domain)}
            >
              backlink list
            </div>
          ),
          assigned_backlinks: (
            <p className="text-center">{domain["domain_assign_backlink"]}</p>
          ),
          free_backlink: (
            <p className="text-center">{domain["not_assign_backlink"]}</p>
          ),
          total_backlinks: (
            <p className="text-center">{domain["total_group_backlink"]}</p>
          ),
          status:
            (domain["status"] === 0 && <Status type="needToStart" />) ||
            (domain["status"] === 1 && <Status type="success" />) ||
            (domain["status"] === 2 && <Status type="inProcess" />),
        }))}
      />
    </div>
  );
}
