import React, { useEffect, useRef, useState } from "react";
import api from "../../services/api";
import { DataTable, InputField, Modal } from "../../components";
import { ToastContainer, toast } from "react-toastify";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import userPermission from "../../utils/userPermission";
import Loading from "../../components/Loader/Loading";
import CustomBreadcrumbs from "../../components/common/CustomBreadcrumbs";

export default function BacklinkReadyStatus() {
  const [isLoading, setIsLoading] = useState(false);

  //   Get Type List
  const [backlinksStatusList, setBacklinksStatusList] = useState([]);
  const getBacklinkStatusList = () => {
    setIsLoading(true);
    api
      .get(`/api/backlinks/backlink_status_list`)
      .then((res) => setBacklinksStatusList(res.data))
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getBacklinkStatusList();
  }, []);

  //   Modal
  const [modalType, setModalType] = useState("");
  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);
  const handleModal = () => {
    setOpen(!open);
  };

  const [ready_status, setStatus] = useState([]);
  const handleNewType = () => {
    setModalType("new");
    handleModal();
  };

  //   Create New Status
  const createReadyStatus = (e) => {
    e.preventDefault();
    setIsLoading(true);
    api
      .post(`/api/backlinks/add_backlink_status`, { ready_status })
      .then((res) => {
        toast.success("new status created");
        getBacklinkStatusList();
      })
      .catch((err) => toast.error(err.response.data.message));
    setStatus("");
    handleModal("");
    setIsLoading(false);
  };

  //   Update Status
  const [statusId, setStatusId] = useState("");
  const hanldeUpdateStatus = (item) => {
    setStatus(item?.ready_status);
    setStatusId(item?.id);
    setModalType("update");
    handleModal();
  };
  const updateStatus = (e) => {
    e.preventDefault();
    setIsLoading(true);
    api
      .put(`api/backlinks/update_backlink_status/${statusId}`, {
        ready_status,
      })
      .then((res) => {
        toast.success("status updated");
        getBacklinkStatusList();
      })
      .catch((err) => toast.error(err.response.data.message));
    setStatus("");
    handleModal("");
    setIsLoading(false);
  };

  //   Delete Status
  const [confirm, setConfirm] = useState("");
  const deleteStatus = (id) => {
    api
      .delete(`/api/backlinks/delete_backlink_status/${id}`)
      .then((res) => {
        toast.success("Type Removed");
        getBacklinkStatusList();
      })
      .catch((err) => toast.error(err.response.data.message));
  };

  return (
    <div className="px-6 pt-4">
      <CustomBreadcrumbs
        crumbs={[
          { label: "Home", link: "/" },
          { label: "Backlinks Ready Status", link: "" },
        ]}
      />
      {isLoading && <Loading />}
      <ToastContainer />
      <div className="mt-2">
      <DataTable
        /*  isLoading={isLoading} */
        extras={
          userPermission("Add Backlink Ready Status") ? (
            <button onClick={handleNewType} className="btnPrimary mt-0">
              Create New
            </button>
          ) : null
        }
        searchBox
        heads={["Sr#", "Ready Status", "actions"]}
        items={backlinksStatusList.map((item, index) => ({
          serialNumber: index + 1,
          type: item.ready_status,
          actions: (
            <div className="relative flex items-center gap-3 text-gray-500">
              {userPermission("Delete Backlink Ready Status") ? (
                <TrashIcon
                  onClick={() => setConfirm(index)}
                  className="w-5 cursor-pointer"
                />
              ) : null}

              {confirm === index && (
                <div className="bg-gray-100 shadow-black/30 z-50 absolute top-0 left-0 mt-5 shadow-xl p-3 rounded-lg flex items-center gap-5">
                  <button onClick={() => setConfirm(null)} className="px-3">
                    Cancel
                  </button>
                  <button
                    onClick={() => deleteStatus(item.id)}
                    className="btnPrimary bg-red-500"
                  >
                    Delete
                  </button>
                </div>
              )}
              {userPermission("Edit Backlink Ready Status") ? (
                <PencilIcon
                  onClick={() => hanldeUpdateStatus(item)}
                  className="w-5 cursor-pointer"
                />
              ) : null}
            </div>
          ),
        }))}
      />
      </div>
      <Modal
        open={open}
        handleModal={handleModal}
        cancelButtonRef={cancelButtonRef}
        className="max-w-2xl"
        title={
          (modalType === "update" && "Update Ready Status") ||
          (modalType === "new" && "Create Ready Status")
        }
        handleModalSubmit={
          (modalType === "update" && updateStatus) ||
          (modalType === "new" && createReadyStatus)
        }
        modalType="new"
      >
        <InputField
          placeholder="Ready Status"
          value={ready_status}
          onChange={(e) => setStatus(e.target.value)}
        />
      </Modal>
    </div>
  );
}
