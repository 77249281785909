import axios from "axios";
import dayjs from "dayjs";
import jwtDecode from "jwt-decode";
import store from "../store";
import { setLoggedInUser } from "../store/slices/authSlice";
const api = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API,
  headers: {
    "Content-Type": "application/json",
  },
});

let refreshTokenPromise;

api.interceptors.request.use(
  async (config) => {
    const { accessToken, refreshToken } = localStorage;
    if (accessToken) {
      const jwt = jwtDecode(accessToken);

      const isExpired = dayjs.unix(jwt.exp).diff(dayjs()) < 3;

      if (!isExpired) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      } else {
        if (!refreshTokenPromise) {
          refreshTokenPromise = axios
            .get("/api/refresh", {
              baseURL: process.env.REACT_APP_PUBLIC_API,
              headers: { refreshToken },
            })
            .then((res) => {
              localStorage.setItem("accessToken", res.data.accessToken);
              return res.data.accessToken;
            })
            .catch((err) => {
              console.log("🚀  file: api.js:57  err:", err);
              if (err.response.status === 403 || err.response.status === 401) {
                logout(refreshToken);
              }
              refreshTokenPromise = null; // reset the promise so it can be used again
              throw err; // propagate the error to the original request
            });
        }

        // wait for the refresh token promise to be resolved with the new access token
        config.headers.Authorization = `Bearer ${await refreshTokenPromise}`;
      }
    }

    return config;
  },
  (error) => {
    console.error("Error to call API", error);
  }
);

let isRefreshing = false;
let refreshTokens = [];
const unauthorizedCode = [401, 403];
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const { accessToken, refreshToken } = localStorage;
    if (
      (error.response.status === 401 || error.response.status === 403) &&
      !originalRequest._retry &&
      refreshToken
    ) {
      originalRequest._retry = true;

      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const res = await axios.get("/api/refresh", {
            baseURL: process.env.REACT_APP_PUBLIC_API,
            headers: { refreshToken },
          });
          if (res?.status === 200) {
            localStorage.setItem("accessToken", res.data.accessToken);
            api.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${res.data.accessToken}`;

            // resolve all the requests in the refresh token queue
            refreshTokens.forEach((cb) => cb(res.data.accessToken));
            refreshTokens.length = 0;

            // update the original request with the new access token and send it again
            originalRequest.headers[
              "Authorization"
            ] = `Bearer ${res.data.accessToken}`;
            return api(originalRequest);
          } else {
            logout(refreshToken);
            return null;
          }
        } catch (e) {
          if (e && unauthorizedCode.includes(e.response.status)) {
            logout(refreshToken);
            // store.dispatch(onLogout())
          }
          console.error(e);
          return e;
        } finally {
          isRefreshing = false;
        }
      } else {
        // if the token is being refreshed, add the request to the queue
        return new Promise((resolve) => {
          refreshTokens.push((token) => {
            originalRequest.headers["Authorization"] = `Bearer ${token}`;
            resolve(api(originalRequest));
          });
        });
      }
    }

    return Promise.reject(error);
  }
);

function logout(refreshToken) {
  axios.get("/api/logout", {
    baseURL: process.env.REACT_APP_PUBLIC_API,
    headers: { refreshToken },
  });
  localStorage.clear();
  store.dispatch(setLoggedInUser(null));
}

export default api;
