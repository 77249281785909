import React, { useState } from "react";
import { Alert, Button, Snackbar, TablePagination } from "@mui/material";
import {
  GridRowModes,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import AddIcon from "@mui/icons-material/Add";
import { FileDownloadOutlined } from "@mui/icons-material";
import api from "../../services/api";
import { FaSearch } from "react-icons/fa";
import { toast } from "react-toastify";
import userPermission from "../../utils/userPermission";

export default function Toolbar(props) {
  const {
    rowModesModel,
    setBacklinksData,
    setRowModesModel,
    fetchBacklinksData,
    paginationModel,
    setPaginationModel,
    setIsLoading,
    selectedFilter,
    handleNewFilter,
    backlinksData,
  } = props;
  const [openSnackbar, setOpenSnackbar] = useState(false);
  //const [isHovered, setIsHovered] = useState(false);
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleClick = () => {
    // Check if there are any unsaved rows before adding a new row
    const unsavedRows = Object.values(rowModesModel).filter(
      (modeModel) => modeModel.mode === GridRowModes.Edit
    );

    if (unsavedRows.length > 0) {
      setOpenSnackbar(true);
      return;
    }

    // Add a new row
    const id = new Date().getTime() % 100000000;
    setBacklinksData((oldRows) => ({
      ...oldRows,
      backlinksData: [{ "b.id": id, isNew: true }, ...oldRows.backlinksData],
    }));
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: "edit", fieldToFocus: "backlink_domain" },
    }));
  };
  /*   const convertArrayToCSV = (data) => {
    const columns = Object.keys(data[0]);

    // Filter the columns to exclude the "Actions" column
    const header = [
      "Sr#",
      "Backlink Domain",
      "Signup URL",
      "City",
      "State",
      "Backlink Location Type",
      "Type",
      "Priority",
      "Review",
      "Blacklisted",
      "Domain Rating",
      "Dofollow Reference Domain",
      "Dofollow Linked Domain",
      "Traffic",
      "Robot Group",
      "Created At",
      "Updated At",
    ];
    const filteredColumns = columns.filter(
      (column) => column !== "records" && column !== "id"
    );

    // Create the header row
    const headerRow = header.join(",") + "\n";

    // Create the data rows
    const dataRows = data
      .map((row) => {
        return filteredColumns
          .map((column) => {
            return row[column];
          })
          .join(",");
      })
      .join("\n");

    // Concatenate the header row and data rows
    const csvContent = headerRow + dataRows;
    return csvContent;
  }; */
  const saveBlobAsExcelFile = (blobData) => {
    const downloadLink = window.URL.createObjectURL(new Blob([blobData]));

    const link = document.createElement("a");
    link.href = downloadLink;
    link.setAttribute("download", `backlinks.xlsx`); // Set the filename

    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    window.URL.revokeObjectURL(downloadLink);
  };
  const downloadCSV = async () => {
    setIsLoading(true);
    try {
      const res = await api.get(
        `${process.env.REACT_APP_PUBLIC_API}/api/backlinks/export_backlink`,
        {
          responseType: "blob", // Specify the response type as Blob
        }
      );
      if (res?.status === 200) {
        const blobData = res.data;
        saveBlobAsExcelFile(blobData);
        toast.success("backlinks export successfully");
      } else {
        toast.error("Backlinks couldn't be export");
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.error("Backlinks couldn't be export");
      console.log("🚀 ~ downloadCSV ~ err:", err);
    }
  };
  const handleTopPageChange = (page) => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: +page + 1 });
    fetchBacklinksData({
      filters: selectedFilter,
      page: +page + 1,
      perPage: paginationModel.pageSize,
    });
  };
  const handleTopPageSizeChange = (newPageSize) => {
    console.log("👊 ~ handleTopPageSizeChange ~ newPageSize:", newPageSize);
    setPaginationModel({ page: 1, pageSize: newPageSize });
    fetchBacklinksData({
      filters: selectedFilter,
      page: 1,
      perPage: newPageSize,
    });
  };

  /*   const filterButtonStyle = {
    borderRadius: "8px",
    backgroundColor: isHovered ? "#8fc3f70a" : "white",
    height: "24px",
    color: "#006bd6",
    border: "1px solid",
    borderColor: "#e8eaee",
    fontSize: "0.8125rem",
  }; */
  return (
    <GridToolbarContainer className="flex items-center space-x-0 my-0 justify-between">
      <div>
        <GridToolbarColumnsButton
          sx={{
            borderRadius: "6px",
            marginRight: "4px",
            border: "1px solid",
            borderColor: "#e8eaee",
            height: "25px",
            paddingLeft: 1,
            paddingRight: 1,
          }}
        />
        <GridToolbarDensitySelector
          sx={{
            borderRadius: "6px",
            marginRight: "4px",
            border: "1px solid",
            borderColor: "#e8eaee",
            height: "25px",
            paddingLeft: 1,
            paddingRight: 1,
          }}
        />
        <GridToolbarFilterButton
          sx={{
            borderRadius: "6px",
            marginRight: "4px",
            border: "1px solid",
            borderColor: "#e8eaee",
            height: "25px",
            paddingLeft: 1,
            paddingRight: 1,
          }}
        />
        {userPermission("Backlink Filters") ? (
          <Button
            variant="text"
            onClick={handleNewFilter}
            startIcon={<FaSearch size={16} />}
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              fontSize: "0.8125rem",
              paddingLeft: 1,
              paddingRight: 1,
            }}
            // className="!text-[#042a42]"
          >
            Apply filter
          </Button>
        ) : null}
        {/* <Button
        variant="text"
        startIcon={<Refresh />}
        sx={{ fontSize: "0.8125rem", background: "" }}
        onClick={() => {
          fetchBacklinksData({
            page: 1,
            perPage: paginationModel?.pageSize,
            filter: [],
          });
          setPaginationModel({ ...paginationModel, page: 1 });
          setSelectedFilter([]);
        }}
        className="text-black btnSecondary"
      >
        Reset Filter
      </Button> */}
        <Snackbar
          open={openSnackbar}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleCloseSnackbar}
        >
          <Alert severity="error">Save all rows before adding a new row.</Alert>
        </Snackbar>
        {userPermission("Add Backlink") ? (
          <Button
            variant="text"
            startIcon={<AddIcon />}
            onClick={handleClick}
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "25px",
              fontSize: "0.8125rem",
              paddingLeft: 1,
              paddingRight: 1,
            }}
          >
            {" "}
            Add Backlink
          </Button>
        ) : null}
        {userPermission("Export") ? (
          <Button
            variant="text"
            startIcon={<FileDownloadOutlined />}
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "25px",
              fontSize: "0.8125rem",
              paddingLeft: 1,
              paddingRight: 1,
            }}
            onClick={downloadCSV}
          >
            Export
          </Button>
        ) : null}
      </div>
      <TablePagination
        color="primary"
        shape="rounded"
        size="medium"
        showFirstButton
        showLastButton
        count={
          backlinksData?.totalCount
            ? backlinksData?.totalCount
            : backlinksData?.backlinksData?.length || 0
        }
        page={paginationModel.page - 1 || 0}
        onPageChange={(e, page) => handleTopPageChange(page)}
        rowsPerPage={paginationModel.pageSize || 100}
        onRowsPerPageChange={(e) => handleTopPageSizeChange(e.target.value)}
        component="div"
        rowsPerPageOptions={[
          25,
          50,
          75,
          100,
          250,
          500,
          1000,
          1500,
          2000,
          {
            label: "All",
            value: backlinksData?.totalCount
              ? backlinksData?.totalCount
              : backlinksData?.backlinksData?.length || 0,
          },
        ]}
      />
    </GridToolbarContainer>
  );
}
