import React from "react";

export default function Pricing() {
  return (
    <div
      id="pricing"
      className="flex items-center flex-col justify-center py-16"
    >
      <div className="flex flex-col w-10/12">
        <div className="w-full text-center flex flex-col items-center">
          <p className="uppercase font-medium">Pricing</p>
          <h2 className="text-4xl font-bold text-center">Choose your plan</h2>
          <p className="mt-3 text-gray-500">
            We have several powerful plans to showcase your business and get
            discovered as a creative entrepreneurs. Everything you need.
          </p>
        </div>
        <div className="grid lg:grid-cols-4 gap-7 mt-10">
          <Plan
            title="Basic"
            des="For Individuals building personal projects."
            price="Free"
            button="free"
            includes={[
              "5 internal / 100 external app users",
              "Essential blocks",
              "5 workspace collaborators",
              "1 custom domain",
            ]}
          />
          <Plan
            title="Professionals"
            des="For SMB and agencies building portals and internal tools."
            price="$139"
            button="free"
            includes={[
              "5 internal / 100 external app users",
              "Essential blocks",
              "5 workspace collaborators",
              "1 custom domain",
            ]}
          />
          <Plan
            title="Business"
            des="For teams building advanced custom apps."
            price="$269"
            button="free"
            includes={[
              "100 internal / 10000 external app users",
              "Timeline, Org chart",
              "15 workspace collaborators",
              "Downloadable mobile apps (PWA)",
            ]}
          />
          <Plan
            className="text-white bg-dark"
            title="Enterprise"
            des="For larger companies that want extra volume, security, and support."
            price="Custom"
            button="Let's talk"
            includes={[
              "SSO for app users (SAML, OpenID)",
              "Security audit",
              "Dedicated success manager",
              "Team training",
              "Custom invoicing",
            ]}
          />
        </div>
      </div>
    </div>
  );
}

function Plan({ title, des, price, includes, className, button }) {
  return (
    <div className={`rounded-2xl border p-8 py-16 shadow-lg ${className}`}>
      <h3 className="font-bold text-3xl">{title}</h3>
      <p className="mt-2 text-gray-500">{des}</p>

      <h3 className="font-bold text-4xl mt-7">{price}</h3>
      <button className="mt-3 btnPrimary text-black w-full rounded-md">
        {button === "free" ? "Start for free" : "Let's talk"}
      </button>
      <p className="text-gray-400 mt-7">Plan Includes</p>
      <ul className="list-disc pl-5 mt-2">
        {includes?.map((item) => (
          <li>{item}</li>
        ))}
      </ul>
    </div>
  );
}
