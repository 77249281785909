import { Tooltip } from "@mui/material";
export const columns = ({
  //handleModal,
  backlinksTypeList,
  backlinksStatusList,
  followStatus,
}) => [
  { field: "counter", headerName: "Sr#", filterable: false, width: 60 },
  {
    field: "b.backlink_domain",
    headerName: "Backlink Domain",
    minWidth: 180,
    editable: true,
    renderCell: (params) => {
      return (
        <a
          href={`https://www.${params.row["b.backlink_domain"]}`}
          className="hover:text-secondary underline"
          target="_blank"
          rel="noreferrer"
        >
          {params.row["b.backlink_domain"]}
        </a>
      );
    },
  },
  // { field: "city", headerName: "City", width: 120, editable: false },
  // { field: "state", headerName: "State", width: 120, editable: false },
  // {
  //   field: "b.signup_url",
  //   headerName: "Signup URL",
  //   width: 100,
  //   editable: true,
  //   filterable: false,
  // },
  // {
  //   field: "source_backlink_domains_list_count",
  //   headerName: "SD",
  //   width: 60,
  //   align: "center",
  //   headerAlign: "center",
  //   renderCell: (params) => (
  //     <span
  //       className="text-[#2563eb] cursor-pointer hover:underline"
  //       onClick={() =>
  //         handleModal(params?.row.records["bi.source_domains_list"])
  //       }
  //     >
  //       {params?.row?.source_domains_list_count}
  //     </span>
  //   ),
  //   filterable: false,
  // },
  /*  {
    field: "b.backlink_location_type",
    headerName: "Backlink Location Type",
    width: 100,
    editable: true,
    renderHeader: (params) => (
      <div className="flex justify- items-center w-full">
        <Tooltip
          title={
            <div className="flex flex-col text-sm w-[full]">
              Backlink Location Type
            </div>
          }
        >
          <span>BL Type</span>
        </Tooltip>
      </div>
    ),
  },
  {
    field: "b.backlink_rank",
    headerName: "Backlink Rank",
    width: 120,
    flex: 1,
    editable: true,
    filterable: false,
    renderHeader: (params) => (
      <div className="flex justify- items-center w-full">
        <Tooltip
          title={
            <div className="flex flex-col text-sm w-[full]">Backlink Rank</div>
          }
        >
          <span>B Rank</span>
        </Tooltip>
      </div>
    ),
  }, */
  {
    field: "b.ready_status_id",
    headerName: "Backlink Ready Status",
    minWidth: 100,
    flex: 1,
    editable: true,
    filterable: true,
    type: "singleSelect",
    getOptionValue: (value) => value?.id,
    getOptionLabel: (value) => value.ready_status,
    valueOptions: backlinksStatusList,
    renderCell: (params) => {
      const status = backlinksStatusList.find(
        (status) => status.id === params.row["b.ready_status_id"]
      );
      return status ? status.ready_status : "";
    },
    valueGetter: (params) => params.row["b.ready_status_id"],
    valueSetter: (params) => {
      if (params.value) {
        const { value } = params;
        const status = backlinksStatusList.find(
          (status) => status.id === value
        );
        if (status) {
          const updatedRow = {
            ...params?.row,
            "b.ready_status_id": params?.value,
            "b.ready_status": status?.ready_status,
          };
          return updatedRow;
        }
      }
      return params;
    },
    valueFormatter: (params) => params.value,
  },
  {
    field: "b.follow_status",
    headerName: "Follow Status",
    minWidth: 100,
    flex: 1,
    editable: true,
    filterable: true,
    type: "singleSelect",
    getOptionValue: (value) => value?.value,
    getOptionLabel: (value) => value.label,
    valueOptions: followStatus,
    renderCell: (params) => params.value["b.follow_status"],
    valueGetter: (params) => params.row["b.follow_status"],
    valueFormatter: (params) => params.value,
  },
  // {
  //   field: "intersect_count",
  //   headerName: "IC",
  //   width: 60,
  //   editable: false,
  //   filterable: false,
  // },
  {
    field: "b.backlink_type_id",
    headerName: "Backlink Type",
    width: 120,
    editable: true,
    align: "left",
    type: "singleSelect",
    getOptionValue: (value) => value?.id,
    getOptionLabel: (value) => value.type,
    valueOptions: backlinksTypeList,
    renderCell: (params) => {
      const status = backlinksTypeList.find(
        (status) => status.id === params.row["b.backlink_type_id"]
      );
      return status ? status.type : "";
    },
    valueGetter: (params) => params.row["b.backlink_type_id"],
    valueSetter: (params) => {
      if (params.value) {
        const { value } = params;
        const status = backlinksTypeList.find((status) => status.id === value);
        if (status) {
          const updatedRow = {
            ...params?.row,
            "b.backlink_type_id": params?.value,
            "b.type": status?.type,
          };
          return updatedRow;
        }
      }
      return params;
    },
    valueFormatter: (params) => params.value,
  },
  {
    field: "b.traffic",
    headerName: "Traffic",
    width: 120,
    editable: true,
    align: "left",
    filterable: false,
  },
  {
    field: "b.domain_rating",
    headerName: "DR",
    width: 80,
    editable: true,
    filterable: false,
    renderHeader: (params) => (
      <div className="flex justify- items-center w-full">
        <Tooltip
          title={
            <div className="flex flex-col text-sm w-[full]">Domain Rating</div>
          }
        >
          <span>DR</span>
        </Tooltip>
      </div>
    ),
  },
  {
    field: "b.created_at",
    headerName: "Created At",
    width: 200,
    editable: false,
    filterable: false,
  },
  {
    field: "b.updated_at",
    headerName: "Updated At",
    width: 200,
    editable: false,
    filterable: false,
  },
];
