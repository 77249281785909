import React from "react";
import LoginForm from "./LoginForm";
import AboutUs from "./AboutUs";
import Footer from "./Footer";
import FAQs from "./FAQs";
import Pricing from "./Pricing";
import Testimonials from "./Testimonials";
import Header from "./Header";

export default function Login() {
  return (
    <div className="bg-white text-blue">
      <Header />
      <LoginForm />
      <AboutUs />
      <Testimonials />
      <Pricing />
      <FAQs
        componentTitle="Frequently Asked Questions"
        options={[
          { question: "lorem ipsum", answer: "lorem ipsum" },
          { question: "lorem ipsum", answer: "lorem ipsum" },
          { question: "lorem ipsum", answer: "lorem ipsum" },
        ]}
      />
      <Footer />
    </div>
  );
}
