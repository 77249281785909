import React, { useEffect, useState } from "react";
import api from "../../services/api";
import { toast } from "react-toastify";
import { InputField } from "../../components";

export default function AddUpdateUserForm({
  handleModal,
  fetchUsersData,
  isLoading,
  setIsLoading,
  modalType,
  user,
  userRole,
}) {
  // New User
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (modalType === "editUser") {
      setName(user.name);
      setEmail(user.email);
      setUsername(user.username);
      setPassword(user.without_encrypted_password);
    } else if (modalType === "newUser") {
      setName("");
      setEmail("");
      setUsername("");
      setPassword("");
    }
  }, [modalType, user, userRole]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Form validation
    const errors = {};

    if (!name.trim()) {
      errors.name = "Please enter the full name.";
    }

    if (!email.trim()) {
      errors.email = "Please enter the email.";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Please enter a valid email address.";
    }

    if (!username.trim()) {
      errors.username = "Please enter the username.";
    }

    if (modalType === "newUser") {
      if (!password.trim()) {
        errors.password = "Please enter the password.";
      }
    }
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      setIsLoading(false);
      return;
    }

    const userData = {
      name,
      email,
      username,
      password,
    };

    if (modalType === "newUser") {
      api
        .post(`${process.env.REACT_APP_PUBLIC_API}/api/users/addUser`, userData)
        .then(() => {
          handleModal();
          fetchUsersData();
          toast.success("New User Created!", { autoClose: 3000 });
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else if (modalType === "editUser" && user.id) {
      api
        .put(`${process.env.REACT_APP_PUBLIC_API}/api/users/updateUser`, {
          id: user.id,
          ...userData,
        })
        .then(() => {
          handleModal();
          fetchUsersData();
          toast.success("User updated successfully!", { autoClose: 3000 });
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <div>
      <div className="grid md:grid-cols-2 gap-4 w-full">
        <InputField
          label="name"
          name="name"
          placeholder="Enter full name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          error={errors.name} // Pass the error prop for validation
          required={true}
        />
        <InputField
          label="email"
          name="email"
          placeholder="Enter user email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={errors.email} // Pass the error prop for validation
          required={true}
        />
        <InputField
          label="username"
          name="username"
          placeholder="Enter username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          disabled={modalType === "editUser"}
          error={errors.username} // Pass the error prop for validation
          required={true}
          autoComplete="new-password"
        />
        <InputField
          label="password"
          name="password"
          placeholder="Enter password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={errors.password} // Pass the error prop for validation
          required={true}
          autoComplete="new-password"
        />
      </div>
      <div className="flex items-cente justify-end mt-5 space-x-2 text-white">
        <button
          onClick={handleModal}
          type="button"
          className="btnPrimary bg-red-500"
        >
          Cancel
        </button>

        {isLoading ? (
          <div className="bg-blue/10 flex items-center justify-center rounded-md py-2 w-48">
            <img src="/img/loading.gif" className="w-7" alt="" />
          </div>
        ) : (
          <button
            type="button"
            onClick={handleSubmit}
            className="btnPrimary bg-green-600"
          >
            Save and Update
          </button>
        )}
      </div>
    </div>
  );
}
