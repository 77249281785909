import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../services/api";
import { DataTable } from "../../components";
import dayjs from "dayjs";
import { ToastContainer } from "react-toastify";
import Loading from "../../components/Loader/Loading";
export default function Batch() {
  const { batchId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [batch, setBatch] = useState([]);

  const getBatchDetail = () => {
    setIsLoading(true);
    api
      .get(`/api/domains/batch_details/${batchId}`)
      .then((res) => setBatch(res.data))
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getBatchDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function separateWords(inputString) {
    return inputString
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .split(/(?=[A-Z])/)
      .join(" ");
  }

  return (
    <div className="px-6 pt-7">
       {isLoading && <Loading />}
      <ToastContainer />
      <DataTable
        searchBox
        title="Batch Detail"
        heads={[
          "Sr#",
          "batch number",
          "domains",
          "backlinks",
          "status",
          "assigned to",
          "Complete Percentage",
          "assigned date",
          "created at",
        ]}
        items={batch?.map((row, index) => ({
          sr: index + 1,
          batch_number: row.batch_number,
          domains: (
            <div className="flex flex-col h-full justify-start">
              {row.domains.map((item, index) => (
                <a
                  href={`https://www.${item}`}
                  className="text-indigo-800 dark:text-indigo-400 hover:text-secondary underline"
                  target="_blank"
                  key={index}
                  rel="noreferrer"
                >
                  {item}
                </a>
              ))}
            </div>
          ),
          backlinks: (
            <div className="flex flex-col h-full justify-start">
              {row.backlinks_names.map((item, index) => (
                <a
                  href={`https://www.${item}`}
                  className="text-indigo-800 dark:text-indigo-400 hover:text-secondary underline"
                  target="_blank"
                  key={index}
                  rel="noreferrer"
                >
                  {item}
                </a>
              ))}
            </div>
          ),
          status: separateWords(row.status),
          assigned_to: row.assigned_to ? row.assigned_to : "Not Assigned",
          percentage: row.completePercentage,
          assigned_date: row.assigned_date
            ? dayjs(row.assigned_date)?.format("ddd, MMM D, YYYY h:mm A")
            : "Not Assigned",
          created_at: dayjs(row.created_at)?.format("ddd, MMM D, YYYY h:mm A"),
        }))}
      />
    </div>
  );
}
