import React, { useState, useRef } from "react";
import api from "../../services/api";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { Modal } from "../../components";
function ImportModal({ setOpen, isImport,fetchTemplates,selectedIndustry,industries }) {
  const [industryId, setIndustryId] = useState(selectedIndustry);
  // const [open, setOpen] = useState(true);
  const [showWarning, setShowWarning] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  //const [errors, setErrors] = useState({});
  const [excelFile, setExcelFile] = useState();
  const handleModal = () => {
    setOpen(!isImport);
  };
  const cancelButtonRef = useRef(null);
  const handleSubmit = (e) => {
    e.preventDefault();
    
    setIsLoading(true);
    const formData = new FormData();
    formData.append("files", excelFile);
    formData.append("industry_id",industryId)
    console.log("Fetching Backlinks");
    api
      .post(
        `${process.env.REACT_APP_PUBLIC_API}/api/backlinks/import_template_backlink_tag`,
        formData,
        {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
      )
      .then((response) => {
        handleModal();
        toast.success("Import data successfully!", { autoClose: 3000 });
        fetchTemplates();
      })
      .catch((error) => {
        console.log("🚀 ~ file: ImportModal.jsx:60 ~ handleSubmit ~ error:", error)
        toast.error(error?.response?.data?.message?error?.response?.data?.message:"Couldn't be import", { autoClose: 3000 });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const onDrop = React.useCallback((acceptedFiles) => {
    let files = acceptedFiles[0];
    setExcelFile(files);
    setShowWarning(false)
  }, []);
  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop,
  });
  return (
    <div>
      <Modal
        open={false}
        handleModal={handleModal}
        cancelButtonRef={cancelButtonRef}
        handleModalSubmit={handleSubmit}
        className="max-w-[50%]"
        title={"Import Backlinks"}
        modalType="backlinks_import"
        isLoading={isLoading}
      >
        <div className="text-base font-medium">
            <span className="font-semibold text-lg font_pop text-red-600">
              Note:{" "}
            </span>{" "}
            File name must be "
            <span className="font-semibold text-lg font_pop text-secondary/80">
            template_backlink_tag.xlsx
            </span>
            " to import in this table
          </div>
          <select
            className="bg-white mt-4 p-2 boder-solid border w-full border-slate-300 rounded mr-1"
            value={industryId}
            onChange={(e) => {
              setIndustryId(e.target.value);
            }}
            name="industry_id"
            // className={`inputField`}
            // error={errors.industry_id}
          >
            <option value={0}>Common</option>
            {industries?.map(({ id, industry_name }, index) => (
              <option key={index} value={id}>
                {industry_name}
              </option>
            ))}
          </select>
          <div className="grid md:grid-cols-1 gap-4">
            <div className="flex flex-col justify-center w-full">
            <div
              className={`!mt-3 w-full flex justify-center border-2 border-dashed rounded-md !p-2 hover:border-yellow-500 focus:outline-none ${
                isDragActive ? "bg-gray-100" : "bg-white"
              }`}
              {...getRootProps()}
            >
              <input {...getInputProps()}/>
              <label
                className={`flex justify-center w-full h-[130px] px-4 transition appearance-none cursor-pointer`}
              >
                {excelFile ? (
                  <span className="flex items-center space-x-2">
                    <div className="flex flex-col text-center">
                      <span className="font-medium text-secondary/80">
                        File Name: {excelFile?.name}
                      </span>
                      <span className="text-gray-600" onClick={open}>
                        Size:{" "}
                        {excelFile?.size > 1000 && excelFile.size < 1000000
                          ? (excelFile.size / 1000).toFixed(2) + " KB"
                          : (excelFile.size / 1000000).toFixed(2) + " MB"}
                      </span>
                    </div>
                  </span>
                ) : (
                  <span className="flex items-center space-x-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 text-gray-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                      />
                    </svg>
                    <span className="font-medium text-gray-600">
                      Drop files to Attach, or{" "}
                      <span className="!text-yellow-500 underline" onClick={open}>
                        browse
                      </span>
                    </span>
                  </span>
                )}
               {/*  <input {...getInputProps()}/> */}
                
              </label>
             
            </div>
           {/*  {errors.file && (
                <div className="text-red-500">{errors.file}</div>
              )} */}
            {showWarning && (
        <p className="text-red-500 text-center">Please select a file</p>
      )}
          </div>
          
          </div>       
      </Modal>
    </div>
  );
}

export default ImportModal;
