import React, { useEffect, useState } from "react";
import api from "../../../services/api";
import { toast } from "react-toastify";
import { XCircleIcon } from "@heroicons/react/24/solid";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

export default function Notes({ task, addNewNote, setAddNewNote,notes, getNotes,newUpdatedTaskId}) {
  const { user } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  // Add Note
  const [note, setNote] = useState("");
  const addNote = (e) => {
    e.preventDefault();
    setIsLoading(true);
    api
      .post(`api/linkbuilder_user/task_note`, {
        task_id: task,
        user_id: user?.user?.id,
        note,
      })
      .then((res) => {
        toast.success(res.data.message);
        setNote("");
        setAddNewNote(false);
      })
      .catch((err) => { 
        toast.error(err.response?.data?.message || err.data.message || 'An error occurred');
      })
      .finally(() => {
        getNotes(newUpdatedTaskId);
        setIsLoading(false);
      });
  };
  return (
    <div className="card px-2 mt-1">
      {addNewNote && (
        <div className="flex items-center justify-between gap-4 px-3 mb-5 ">
          <XCircleIcon
            onClick={() => setAddNewNote(false)}
            className="text-red-500 dark:text-red-400 w-8 cursor-pointer"
          />
          <textarea
            rows="1"
            value={note}
            onChange={(e) => setNote(e.target.value)}
            className="inputField py-2 dark:bg-dark dark:border-white/50"
            placeholder="Write new note"
          ></textarea>
          <div className="flex items-center justify-end gap-2">
            <button
              onClick={() => setAddNewNote(false)}
              type="button"
              className="btnPrimary bg-red-500"
            >
              Cancel
            </button>
            {isLoading ? (
              <div className="bg-blue/10 flex items-center justify-center rounded-md py-2 w-48">
                <img src="/img/loading.gif" className="w-7" alt="" />
              </div>
            ) : (
              <button onClick={addNote} className="btnPrimary bg-green-600">
                Save
              </button>
            )}
          </div>
        </div>
      )}
      <div className={notes.length > 6 && "max-h-[55vh] overflow-y-scroll"}>
        {notes.map((item, index) => (
          <div
            key={index}
            className="flex items-center justify-between gap-3 py-2 px-3 border-b dark:border-white/40 dark:text-white/80 hover:bg-gray-100 dark:hover:bg-white/10"
          >
            <p>{item.note}</p>
            <p className="text-xs">
              {dayjs(item.created_at)?.format("ddd, MMM D, YYYY h:mm A")}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}
