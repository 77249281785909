import React, { useEffect, useState } from "react";
import { DataTable } from "../../components";
import api from "../../services/api";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loader/Loading";
import CustomBreadcrumbs from "../../components/common/CustomBreadcrumbs";
import { useDispatch } from "react-redux";
import { domainReset } from "../../store/slices/taskPrevDomainSlice";
export default function LinkBuildersReport() {
  const dispatch = useDispatch();
  const [LinkBuilders, setLinkBuilders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const defaultFilter = [{ field: "role_id", op: "isAnyOf", data: [3, 7, 8] }];
  const get_user = async (filter) => {
    setIsLoading(true);
    let payload = { ...filter };
    if (!payload.filters?.rules?.find(({ field }) => field === "role_id")) {
      payload.filters?.rules.push(defaultFilter[0]);
    }
    if (!payload?.filters?.rules?.length) {
      delete payload.filters;
    }
    delete payload?.pageSize;
    try {
      const res = await api.post("/api/users/users_report", payload);
      if (res.status === 200) {
        setLinkBuilders(res.data?.records);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("🚀 ~ getSERPReport ~ err:", err);
    }
  };
  // const fetchLinkBuildersList = () => {
  //   setIsLoading(true);
  //   api
  //     .get(`/api/linkbuilder_user/`)
  //     .then((res) => setLinkBuilders(res.data))
  //     .catch((err) => console.log(err.data.message))
  //     .finally(() => setIsLoading(false));
  // };

  useEffect(() => {
    get_user({
      filters: { groupOp: "AND", rules: [] },
      page: 1,
      perPage: 1000,
    });
    dispatch(domainReset());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const navigate = useNavigate();
  const handleUserTask = (userId, filter) => {
    navigate(`/settings/link-builders/${userId}`, {
      state: {
        filter,
        taskDomainData: "",
      },
    });
  };

  return (
    <div className="px-6 pt-4">
      {isLoading && <Loading />}
      <CustomBreadcrumbs
        crumbs={[
          { label: "Home", link: "/" },
          { label: "Link Builders Reports", link: "" },
        ]}
      />
      <DataTable
        heads={[
          "Sr#",
          "Name",
          "Role",
          "Username",
          "Email",
          "total tasks",
          "pending",
          "in process",
          "in review",
          "on hold",
          "cancelled",
          "completed",
        ]}
        items={LinkBuilders?.map((user, index) => {
          const { task_info } = user;
          const totalTask = (task_info || []).reduce((total, item) => {
            return total + item.totals;
          }, 0);
          const pending =
            task_info?.find(({ task_status }) => task_status === "pending")
              ?.totals || 0;
          const completed =
            task_info?.find(({ task_status }) => task_status === "completed")
              ?.totals || 0;
          const inProcess =
            task_info?.find(({ task_status }) => task_status === "inProcess")
              ?.totals || 0;
          const hold =
            task_info?.find(({ task_status }) => task_status === "hold")
              ?.totals || 0;
          const cancelled =
            task_info?.find(({ task_status }) => task_status === "cancelled")
              ?.totals || 0;
          const inReview =
            task_info?.find(({ task_status }) => task_status === "inReview")
              ?.totals || 0;
          return {
            serialnumber: index + 1,
            name: user["name"],
            role: user["role"],
            username: user["username"],
            email: user["email"],
            totalTasks: (
              <button
                onClick={() => handleUserTask(user.id, "")}
                className="text-center bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3"
              >
                {totalTask}
              </button>
            ),
            pending: (
              <button
                onClick={() => handleUserTask(user.id, "pending")}
                className="text-center bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3"
              >
                {pending}
              </button>
            ),
            inProcess: (
              <button
                onClick={() => handleUserTask(user.id, "inProcess")}
                className="text-center bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3"
              >
                {inProcess}
              </button>
            ),
            inReview: (
              <button
                onClick={() => handleUserTask(user.id, "inReview")}
                className="text-center bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3"
              >
                {inReview}
              </button>
            ),
            onHold: (
              <button
                onClick={() => handleUserTask(user.id, "hold")}
                className="text-center bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3"
              >
                {hold}
              </button>
            ),
            cancelled: (
              <button
                onClick={() => handleUserTask(user.id, "cancelled")}
                className="text-center bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3"
              >
                {cancelled}
              </button>
            ),
            completed: (
              <button
                onClick={() => handleUserTask(user.id, "completed")}
                className="text-center bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3"
              >
                {completed}
              </button>
            ),
          };
        })}
      />
    </div>
  );
}
