import React, { useState } from "react";
import dayjs from "dayjs";
import { InputField, SelectBox } from "../../components";
import api from "../../services/api";
import CustomTreeData from "./CustomTreeData";
import Loading from "../../components/Loader/Loading";
import CustomBreadcrumbs from "../../components/common/CustomBreadcrumbs";
import { Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
const Mailbox = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const emailId = queryParams.get("emailId");
  const backlinkId = queryParams.get("backlinkId");
  const [backlinks, setBacklinks] = useState([]);
  const initalData = {
    subjectText: null,
    fromEmail: emailId || "",
    numberOfMails: null,
    backlink: backlinkId || "",
    emailDate: dayjs(new Date()).format("YYYY-MM-DD"),
  };
  const [isLoader, setIsLoader] = React.useState(false);
  const [emailList, setEmailList] = React.useState([]);
  const [selectedPayload, setSelectedPayload] = React.useState(initalData);
  const [expandedRow, setExpandedRow] = useState(null);
  const [additionalData, setAdditionalData] = useState(null);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setSelectedPayload({ ...selectedPayload, [name]: value });
  };
  const get_docusign_template_list = async (filter) => {
    setIsLoader(true);
    const payload = {
      subjectText: filter?.subjectText,
      fromEmail: filter?.fromEmail,
      numberOfMails: filter?.numberOfMails,
      backlink: filter?.backlink,
      date: filter?.emailDate,
    };
    const queryParams = Object.entries(payload)
      .filter(([key, value]) => value !== null && value !== "")
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    const dynamicUrl = `q=&${queryParams}`;
    try {
      const res = await api.get(`api/linkbuilder_user/get_mails?${dynamicUrl}`);
      if (res.status === 200) {
        setEmailList(res.data);
      }
      setIsLoader(false);
    } catch (err) {
      console.log(err);
      toast.error(
        err?.response?.data?.message || "Please reload the page and try again!"
      );
      setEmailList([]);
      setIsLoader(false);
    }
  };
  React.useEffect(() => {
    get_docusign_template_list(selectedPayload);
    getBacklinks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onReset = () => {
    setSelectedPayload(initalData);
    get_docusign_template_list(initalData);
  };
  const renderContent = (content) => {
    const isHTML = /<\/?[a-z][\s\S]*>/i.test(content);
    if (isHTML) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(content, "text/html");
      const plainText = doc.body.textContent || "";
      return plainText;
    }

    return content;
  };
  const handleRowClick = async (itemId) => {
    if (expandedRow === itemId?.id) {
      setExpandedRow(null);
      setAdditionalData([]);
    } else {
      if (itemId) {
        setAdditionalData(itemId);
        setExpandedRow(itemId?.id);
      }
    }
  };

  const getBacklinks = async () => {
    try {
      const response = await api.get(
        `${process.env.REACT_APP_PUBLIC_API}/api/backlinks/assigned_backlinks`
      );
      setBacklinks(response?.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleSelectedOptionsChange = (event) => {
    setSelectedPayload({
      ...selectedPayload,
      backlink: event.target.value,
    });
  };

  const readFrom = (response) => {
    response = response.trim();
    const quotedMatch = response.match(/^"([^"]+)"\s*</);
    if (quotedMatch) {
      return quotedMatch[1];
    }
    const angleBracketMatch = response.match(/^([^<\s]+)(?:\s*<|$)/);
    if (angleBracketMatch) {
      return angleBracketMatch[1].trim();
    }
    return response.split("<")[0].trim();
  };

  return (
    <div className="px-6 pt-4">
      <ToastContainer />
      <CustomBreadcrumbs
        crumbs={[
          { label: "Task List", link: "/" },
          { label: "Mailbox", link: "" },
        ]}
      />
      <div className="h-full p-4 bg-neutral-1 bg-white border mt-2">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-2 items-center">
          <InputField
            placeholder={"Subject Text"}
            onChange={handleChange}
            name="subjectText"
            value={selectedPayload?.subjectText}
            showFilterIcon={true}
          />
          <InputField
            placeholder={"From Email"}
            onChange={handleChange}
            name="fromEmail"
            value={selectedPayload?.fromEmail}
            showFilterIcon={true}
          />
          <InputField
            placeholder={"No. of Mails"}
            onChange={handleChange}
            name="numberOfMails"
            value={selectedPayload?.numberOfMails}
            showFilterIcon={true}
          />
          <SelectBox
            placeholder={"Select Backlink"}
            onChange={handleSelectedOptionsChange}
            name="Backlinks"
            valueKey="backlink_domain"
            labelKey="backlink_domain"
            options={backlinks}
            value={selectedPayload?.backlink}
            selectValue="Select Backlink"
            height="h-[42px]"
          />
          <div className="col-span-1 flex flex-col sm:flex-row items-center sm:items-center">
            <InputField
              placeholder={"Email Date"}
              onChange={handleChange}
              name="emailDate"
              value={selectedPayload?.emailDate}
              type={"date"}
              showFilterIcon={true}
            />
            <div className="flex mt-2 sm:mt-0 sm:ml-3">
              <button
                className="py-1.5 px-3 mr-2 bg-secondary text-white btnSecondary"
                onClick={() => get_docusign_template_list(selectedPayload)}
              >
                Search
              </button>
              <button
                className="py-1.5 px-3 bg-[#233b5b] text-white cursor-pointer relative z-10 rounded"
                onClick={onReset}
              >
                Reset
              </button>
            </div>
          </div>
          <div className="col-span-1 flex justify-center sm:justify-end">
            <Tooltip
              title={
                emailList?.length && emailList[0]?.email ? (
                  <Typography color="inherit">
                    <span>{emailList[0]?.email}</span>
                  </Typography>
                ) : (
                  "-"
                )
              }
              arrow
            >
              <img
                src="/img/user.png"
                alt="Avatar"
                className="w-[40px] rounded-full ml-2"
              />
            </Tooltip>
          </div>
        </div>
      </div>
      {isLoader && <Loading />}
      <div className="bg-white my-3 border rounded">
        <CustomTreeData
          searchBox
          totalRecords={emailList?.length}
          tdClass={"!whitespace-normal text-wrap"}
          heads={[" ", "Sr#", "From", "Subject", "Time"]}
          items={emailList?.map((item, index) => ({
            id: item?.id,
            serialNumber: index + 1,
            from: readFrom(item?.from),
            subject: item?.subject,
            mailcontent: item?.htmlContent,
            isRead: item.isRead,
            time: dayjs(item.time).format("ddd, MMM D, YYYY h:mm A"),
            snippet: item?.snippet,
          }))}
          rowClass="flex"
          cellClass="flex-1"
          onRowClick={handleRowClick}
          expandedRow={expandedRow}
          additionalData={additionalData}
          renderContent={renderContent}
        />
      </div>
    </div>
  );
};
export default Mailbox;
